// External imports
import React, { Fragment, useEffect } from 'react';
import {
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  AppBar,
  Toolbar,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import VerifyCircle from '@material-ui/icons/VerifiedUserRounded';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
import { areAgreementsSigned, isSuperOrTechSupport } from '~/app/Utility/general';
import XlinkAPI from '~/app/api/xlinkAPI';
import WebHelpers, { statusOK } from '~/app/webHelpers.js';
import ErrorHelpers from '~/app/errorHelpers.js';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import {
  MENU_TYPE,
  HIERARCHY_TYPE,
  VERIFY_MODAL_TABS,
  PRINT_TYPES,
  LATEST_SEASON,
} from '~/app/constants.js';
import AccessControl from '~/app/Components/Auth/AccessControl.jsx';
import { isShortScreenRez } from '~/app/Utility/browser';
import {
  mainMenuOptions,
  printMenuOptions,
  resetMenuAnchors,
} from '~/app/Pages/Returns/components/HeaderBarForms/headerBarFormHelper.js';
// Redux imports
import { useDispatch, useSelector } from 'react-redux';
import { actions as appActions } from '~/app/redux/modules/app.js';
import { actions as formViewerActions } from '~/app/redux/modules/formViewer.js';
import { actions as returnProfileActions } from '~/app/redux/returnProfile/duck.js';
import { selectors as loginSelector } from '~/app/redux/loginSetup/selectors';
// Styling imports
import '~/app/Pages/Returns/components/HeaderBarForms/css/headerBarForms.css';
import {
  headerBarStyles,
  styles,
} from '~/app/Pages/Returns/components/HeaderBarForms/css/headerBarForms.js';
// Image imports
import queueIcon from '~/images/icons/queueIcon.svg';
import paymentIcon from '~/images/icons/payment_icon.svg';
import printIcon from '~/images/icons/icons8-print.svg';
import questionIcon from '~/images/icons/icons8-ask_question.svg';
import contractJobIcon from '~/images/icons/icons8-contract-job.svg';
import notesIcon from '~/images/icons/notes-button-icon.svg';
import interviewIcon from '~/images/icons/icons8-interview.svg';
import magicWand from '~/images/icons/icons8-magic-wand.svg';
import handSigningIcon from '~/images/icons/icons8-hand-signature.svg';
import HelpMenu from '~/app/Components/HeaderBar/components/HelpMenu/HelpMenu.jsx';

const themeStyles = theme => headerBarStyles(theme);

/**
 * Handles rendering the header for formviewer at "/tax-returns"
 *
 * Office accounts can unlock all returns and are allowed permissions
 * Preparer cannot unlocked returns that are locked due to return status
 * All return functionality is completely locked if there is a user currently in the return and the 'lockedBy' prop is populated
 *
 * @component HeaderBarForms
 * @category FormViewer
 */
const HeaderBarForms = props => {
  const { classes } = props;
  const dispatch = useDispatch();
  const {
    scrollPositionInit,
    snackbarMessage,
    officeProfile,
    clientFirstName,
    clientLastName,
    friendlyID,
    lockedStatus,
    lockedBy,
    lockedViaAccessLevel,
    isWizard,
    wizardCompleted,
    returnStatus,
    agreementsStatus,
    wizardFirstName,
    wizardLastName,
    wizardCurrentStep,
    isHelpMenuVisible,
    activeAccessLevels,
  } = useSelector(state => ({
    scrollPositionInit: state.app.scrollPositionInit,
    snackbarMessage: state.app.snackbarMessage,
    techSupportNum: state.cobrand.techSupportNumber,
    officeProfile: state.officeProfile,
    clientFirstName: state.returnProfile.clientFirstName || 'NO',
    clientLastName: state.returnProfile.clientLastName || 'NAME',
    friendlyID: state.returnProfile.friendlyID,
    lockedStatus: state.returnProfile.lockedStatus,
    lockedBy: state.returnProfile.lockedBy,
    lockedViaAccessLevel: state.returnProfile.lockedViaAccessLevel,
    isWizard: state.returnProfile.isWizard,
    wizardCompleted: state.returnProfile.wizardCompleted,
    returnStatus: state.returnProfile.returnStatus,
    agreementsStatus: state.overview.agreementsStatus,
    wizardFirstName: state.wizardEstimator.wizardFormFields.US1W?.US1WPNMA || '',
    wizardLastName: state.wizardEstimator.wizardFormFields.US1W?.US1WPNMC || '',
    wizardCurrentStep: state.wizardEstimator.wizardCurrentStep,
    isHelpMenuVisible: state.app.isHelpMenuVisible,
    activeAccessLevels: loginSelector.getActiveAccessLevels(state),
  }));

  const [state, setState] = useSetState({
    isPrintMenuOpen: false,
    isMainMenuOpen: false,
    isHelpModalOpen: false,
    isMissingEROSignatureModalOpen: false,
    scrollPositionInit: false,
    sendLoginID: 0,
    allowPrintMenuFunctionality: false,
    requiredTextLinkFieldsExist: false,
    isUnlockConfirmationOpen: false,
    anchorEl: {},
    isEFINAccount: false, // if current view is an Office, allow them unlocking permissions.
    // printOptions for use when printing from a locked return.
    // keeps track of selected print type to allow for printing after displaying the tax return unlock modal.
    printOptions: {
      printType: '',
      val: '',
      menuText: '',
    },
    clientName: props.clientName,
    returnTitle: '',
  });
  const payload = WebHelpers.getJWTPayload();

  // TODO --> Remove these when formviewer doesn't rely on hacky css and we properly format it.
  useEffect(() => {
    window.addEventListener('resize', () => resetMenuAnchors(handleMenuClose));

    let paramLoginID = 0;

    if (props.loginID !== undefined || props.loginID !== '') {
      paramLoginID = props.loginID;
      setState({ sendLoginID: paramLoginID });
    }

    setState({
      scrollPositionInit,
      hasNoPrintAccess: props.prefs.no_access,
      isEFINAccount: props.currentView?.role === HIERARCHY_TYPE.EFIN,
    });
    return () => {
      window.removeEventListener('resize', () => resetMenuAnchors(handleMenuClose));
    };
  }, []);

  useEffect(() => {
    if (wizardCurrentStep === 0) {
      const updateName = wizardFirstName + ' ' + wizardLastName;
      setState({ clientName: updateName });
    }
  }, [wizardFirstName, wizardLastName]);

  useEffect(() => {
    const returnTitle = `${props.taxYear} ${`${payload?.is_business ? 'Business' : ''}${
      isWizard ? 'Guided Estimator Return' : ''
    }  - ${
      payload?.is_business || isWizard
        ? `${props.clientName || 'NO NAME'}`
        : `${clientFirstName} ${clientLastName}`
    }`} `;

    setState({ returnTitle });
  }, [
    props.taxYear,
    payload?.is_business,
    props.clientName,
    clientFirstName,
    clientLastName,
    isWizard,
  ]);
  /**
   * Handles accepting the users desired printing func, and sends up that to parent helper
   * TODO --> Remove above when formviewer doesn't rely on hacky css and we properly format it.
   *
   * @param {string} printType type of print function the user wants t odo
   * @param {string} val value of the the printing option
   * @param {string} menuText description of the selected option
   */
  const handlePrintOptions = (printType, val = '', menuText) => {
    setState({
      printOptions: {
        ...state.printOptions,
        printType,
        val,
        menuText,
      },
    });

    if (!props.usingDemoAccount) {
      if (!state.allowPrintMenuFunctionality && printType === PRINT_TYPES.EF_DOCUMENTS) {
        setState({ isMissingEROSignatureModalOpen: true });
      } else {
        const prefs = props.convertPrintPrefs(props.prefs, printType);
        props.handlePrint(printType, prefs, val, menuText);
      }
    } else {
      props.handleNoPrintDemo();
    }
  };

  /** Handles determining whether the print return component should display a confirmation modal before redirecting user */
  const returnComponentsLink = () => {
    if (state.allowPrintMenuFunctionality) {
      props.openPrintReturnComponents();
    } else {
      setState({ isMissingEROSignatureModalOpen: true });
    }
  };

  /**  Handles fetching state ack letters based on whether the user is in a demo account */
  const handleStateAck = () => {
    if (!props.usingDemoAccount) {
      props.getStateAckList();
    } else {
      props.handleNoPrintDemo();
    }
  };

  /**  Handles fetching state ack letters based on whether the user is in a demo account */
  const handleStateAmendedAck = () => {
    if (!props.usingDemoAccount) {
      props.getAmendedStateAckList();
    } else {
      props.handleNoPrintDemo();
    }
  };

  /** Handles fetching state client letters based on whether the user is in a demo account */
  const handleStateClientLetters = () => {
    if (!props.usingDemoAccount) {
      props.getAttachedStates();
    } else {
      props.handleNoPrintDemo();
    }
  };

  /** Handles the Printing the Final Return */
  const handlePrintFinal = () => {
    if (!props.usingDemoAccount && state.allowPrintMenuFunctionality) {
      props.setVerifyType('printFinal');
      props.verifyReturn(VERIFY_MODAL_TABS.VERIFY);
    } else {
      if (!state.allowPrintMenuFunctionality) {
        setState({ isMissingEROSignatureModalOpen: true });
      } else {
        props.handleNoPrintDemo();
      }
    }
  };

  /**
   * Handles first making a request to verifying the user before allowing the dropdown to display.
   *
   * @param {HTML} currentTarget this is the current target from a DOM Event - in this case, the button for the dropdown
   */
  const handlePrintMenu = async currentTarget => {
    // only making one request for access and saving result to state
    if (!state.allowPrintMenuFunctionality) {
      dispatch(formViewerActions.signatureRequestActiveSet(false));

      // Call backend here in order to check if we currently have a signature for ERO, if not we need to block the print options from the menu
      try {
        const res = await XlinkAPI.checkEROSignatureExistsFlag(state.sendLoginID);

        // Endpoint data returns back boolean as data.
        if (statusOK(res, false) && typeof res?.data === 'boolean') {
          setState({
            anchorEl: currentTarget,
            isPrintMenuOpen: res.data,
            allowPrintMenuFunctionality: res.data,
            isMissingEROSignatureModalOpen: !res.data, // Open ERO Signature requirement if false
          });
        }
      } catch (error) {
        dispatch(
          appActions.showSnackbarMessage(error, 'error', 3500, {
            vertical: 'top',
            horizontal: 'center',
          }),
        );
        ErrorHelpers.handleError('Error fetching ERO signature flag', error);
      }
    } else {
      setState({
        anchorEl: currentTarget,
        isPrintMenuOpen: true,
      });
    }
  };

  /**
   * Handles closing a popup menu based on the menu name
   *
   * @param {string} menuName name of menu
   */
  const handleMenuClose = menuName => {
    setState({
      [menuName]: false,
      anchorEl: {},
    });
  };

  /**
   * Handles first checking if required fields have been filled in before allow the dropdown to display
   *
   * @param {HTML} currentTarget this is the current target from a DOM Event - in this case, the button for the dropdown
   */
  const handleMainMenu = currentTarget => {
    requiredTextLinkFieldsExist();
    setState({ isMainMenuOpen: true, anchorEl: currentTarget });
  };

  /** Handles the logic of closing the return and whether to save, lock, confirm, or 'save and close' */
  const closeReturn = () => {
    if (
      lockedBy ||
      props.isSuperOrTechUserReadOnly() ||
      (officeProfile.is_feeder_office && props.readyForReview)
    ) {
      props.closeReturn();
    } else if (isLocked()) {
      props.showConfirmClose();
    } else {
      props.closeAndSaveReturn();
    }
  };

  /** Handles checking the required fields, and saving a completion bool to local state */
  const requiredTextLinkFieldsExist = async () => {
    try {
      const res = await XlinkAPI.getTextMessageClientInfo(props.returnID);
      if (statusOK(res)) {
        if (hasValidTextLinkFields(res.data[0])) {
          setState({ requiredTextLinkFieldsExist: true });
          dispatch(formViewerActions.textLinkClientInfoFetched(res.data));
        } else {
          setState({ requiredTextLinkFieldsExist: false });
        }
      }
    } catch (error) {
      dispatch(
        appActions.showSnackbarMessage(error, 'error', 3500, {
          vertical: 'top',
          horizontal: 'center',
        }),
      );
      ErrorHelpers.handleError('Error fetching message info', error);
    }
  };

  const hasValidTextLinkFields = returnInfo => {
    const validFirstName = clientFirstName && clientFirstName !== 'NO';
    const validLastName = clientFirstName && clientLastName !== 'NAME';
    const validName = returnInfo.businessName || (validFirstName && validLastName);

    return (
      returnInfo.cellPhone.length > 8 &&
      returnInfo.cellCarrier &&
      returnInfo.carrierDomain &&
      validName
    );
  };
  /** Handles checking if the user is allowed to open the textlink modal to send a text */
  const handleSendText = () => {
    if (props.isTextLinkActivated === false) {
      dispatch(formViewerActions.toggleTextLinkNotActivatedDialog(true));
    } else if (state.requiredTextLinkFieldsExist) {
      props.handleOpenTextLinkSendModal();
    } else {
      dispatch(formViewerActions.toggleShowNoTextLinkClientsDlg(true));
    }
  };

  /** Handles whether or not to display the textlink recieve modal */
  const handleReceiveText = () => {
    if (props.isTextLinkActivated === false) {
      dispatch(formViewerActions.toggleTextLinkNotActivatedDialog(true));
    } else if (state.requiredTextLinkFieldsExist) {
      dispatch(formViewerActions.toggleTextLinkReceivedModal(true));
    } else {
      dispatch(formViewerActions.toggleShowNoTextLinkClientsDlg(true));
    }
  };

  /**
   * Handles checking whether the user is a prep or efin
   *
   * @returns {boolean} used to determine whether or not the user is a prep OR a efin
   */
  const notPrepOrEFIN = () => {
    return (
      props.currentView?.role !== HIERARCHY_TYPE.EFIN &&
      props.currentView?.role !== HIERARCHY_TYPE.PREPARER
    );
  };

  /**
   * Handles checking if tax return matches latest season
   *
   * @returns {boolean}
   */
  const notLatestSeason = () => {
    return props.season !== LATEST_SEASON;
  };

  /**
   * Handles the selected option from the user and running the logic for that option
   * This allows us to move the menu option/list to a constant file, and only pass in one function rather than all list below
   *
   * @param {string} currentMenu This is name of the current menu used to determine which menu is being accessed
   * @param {string} selectedOption this is the name of the option selected and used to execute it's specific logic
   */
  const handleMenuSelection = (currentMenu, selectedOption) => {
    switch (currentMenu) {
      // Each menu has their own switch statement of their listed options
      // Main Menu dropdown
      case 'mainMenu':
        switch (selectedOption) {
          case 'submitForReview':
            props.setReturnReviewStatusDialog();
            break;
          case 'toggleLockReturn':
            props.toggleReturnStatusLock();
            break;
          case 'reloadBilling':
            props.reloadBilling();
            break;
          case 'yearToYear':
            props.showYearToYear();
            break;
          case 'sendText':
            handleSendText();
            break;
          case 'recieveText':
            handleReceiveText();
            break;
          case 'toggleTaxPassModal':
            dispatch(formViewerActions.toggleTaxpassMessageModal());
            break;
          case 'deleteReturn':
            props.showConfirmDelete();
            break;
          case 'copyToTraining':
            props.copyToTraining();
            break;
          case 'copyToLive':
            props.copyToLiveDupeCheck();
            break;
          case 'saveAndClose':
            props.closeAndSaveReturn();
            break;
          case 'close':
            props.showConfirmClose();
            break;
        }
        break;
      // Print Menu dropdown
      case 'printMenu':
        switch (selectedOption) {
          case 'currentFormOnly':
            props.handlePrint(PRINT_TYPES.CURRENT_FORM, {}, 'Current Form Only');
            break;
          case 'returnComponents':
            returnComponentsLink();
            break;
          case 'finalTaxReturn':
            handlePrintFinal();
            break;
          case 'bankDocuments':
            handlePrintOptions(PRINT_TYPES.BANK_DOCUMENTS, '', 'Bank Documents');
            break;
          case 'privacyDisclosure':
            handlePrintOptions(PRINT_TYPES.DISCLOSURE, '', 'Disclosure/Privacy');
            break;
          case 'scheduleK1s':
            handlePrintOptions(PRINT_TYPES.CORP_SCHEDULE_K1S, '', 'Schedule K-1s');
            break;
          case 'organizer':
            handlePrintOptions(PRINT_TYPES.ORGANIZER, '', 'Organizer/Proforma');
            break;
          case 'efDocuments':
            handlePrintOptions(PRINT_TYPES.EF_DOCUMENTS, '8879/8453 EF Documents');
            break;
          case 'amendedReturn':
            props.handlePrint(PRINT_TYPES.AMENDED_RETURN, {}, 'Amended Return');
            break;
          case 'ackLetter':
            handlePrintOptions(PRINT_TYPES.ACK_LETTER);
            break;
          case 'stateAckLetter':
            handleStateAck();
            break;
          case 'stateAmendedAckLetter':
            handleStateAmendedAck();
            break;
          case 'amendedAckLetter':
            handlePrintOptions(PRINT_TYPES.AMENDED_ACK_LETTER, '', 'Amended ACK Letter');
            break;
          case 'fedClientLetter':
            handlePrintOptions(PRINT_TYPES.CLIENT_LETTER, 'Federal', 'Federal Client Letter');
            break;
          case 'stateClientLetter':
            handleStateClientLetters();
            break;
          case 'engagementLetter':
            handlePrintOptions(PRINT_TYPES.CLIENT_LETTER, 'Engagement', 'Engagement Letter');
            break;
          case 'paymentVoucher':
            handlePrintOptions(PRINT_TYPES.PAYMENT_VOUCHER, '', 'Payment Voucher');
            break;
          case 'extensionRequest':
            handlePrintOptions(PRINT_TYPES.EXT_REQUEST, '', 'Extension Request');
            break;
          case 'extensionAckLetter':
            handlePrintOptions(PRINT_TYPES.EXT_ACK_LETTER, '', 'Extension ACK Letter');
            break;
          case 'referralCoupons':
            handlePrintOptions(PRINT_TYPES.REF_COUPONS, '', 'Referral Coupons');
            break;
          case 'attachedFormsList':
            props.handleAttachedFormsPrint();
            break;
          case 'previewReturn':
            handlePrintOptions(PRINT_TYPES.PREVIEW_RETURN, '', 'Preview Return');
            break;
          case 'paperFileReturn':
            handlePrintOptions(PRINT_TYPES.PAPER_FILE_RT, '', 'Paper File RT Return');
            break;
        }
    }
    resetMenuAnchors(handleMenuClose);
  };

  /**
   * Handles rendering the items/options in a menu/dropdown
   * TODO --> Maybe not the best approach, but cleaner than using repeated code. OPTIMIZE AND EXPAND?!?
   *
   * @param {string} menuOption the name of the menu option
   * @returns
   */
  const renderMenuItems = menuName => {
    let menuArr = [];

    // Check Flag to see which menu to render
    switch (menuName) {
      case 'printOptions':
        menuArr = printMenuOptions(handleMenuSelection, props.isTrainingMode, payload?.is_business);
        break;
      case 'menuOptions':
        menuArr = mainMenuOptions(
          handleMenuSelection,
          notPrepOrEFIN(),
          isLocked(),
          props.readyForReview,
          officeProfile.is_feeder_office,
          props.isTrainingMode,
          props.isTaxPassReturn,
          props.toggleYearToYearOptions,
          notLatestSeason(),
          lockedBy,
          props.isSuperOrTechUserReadOnly(),
          props.usingDemoAccount,
          canCopyToLiveReturn(),
          returnStatus === 'Acknowledged',
          payload?.is_business,
          props.isFeeder,
          props.transmissionBlocked,
          isViewReturnOnly(),
        );
        break;
    }

    return menuArr.map((menuItem, index) => {
      const hideMenuItem = menuItem.hideMenuItem === undefined ? false : menuItem.hideMenuItem;
      const showMenuItem =
        !(menuItem === undefined) || (menuItem.render === undefined ? false : menuItem.render);
      const hiddenByYear =
        menuItem.seasons === undefined ? false : !menuItem.seasons.includes(props.season);

      if (menuItem?.disabled) {
        return null;
      }

      const altMenuItem = menuItem?.accessChecks?.alternateMenu ? (
        renderMenuItem(
          menuItem.accessChecks.alternateMenu,
          menuName,
          showMenuItem,
          hiddenByYear,
          index,
          hideMenuItem,
        )
      ) : (
        <Fragment />
      );

      switch (menuItem?.type) {
        case MENU_TYPE.MENU_ITEM:
          return menuItem?.accessChecks ? (
            <AccessControl
              key={'item' + index + menuName}
              requiredAction={menuItem.accessChecks.permission}
              accessLevel={menuItem.accessChecks.accessLevel}
              alternateView={altMenuItem}
            >
              {menuItem.accessChecks.hideOnAccess ? (
                <Fragment />
              ) : (
                renderMenuItem(menuItem, menuName, showMenuItem, hiddenByYear, index, hideMenuItem)
              )}
            </AccessControl>
          ) : (
            renderMenuItem(menuItem, menuName, showMenuItem, hiddenByYear, index, hideMenuItem)
          );
        case MENU_TYPE.DIVIDER:
          return (
            <Divider
              className={classes.headerBarFormsDivider}
              hidden={!showMenuItem}
              key={'divider' + index + menuName}
            />
          );
        case MENU_TYPE.LINE_BREAK:
          return <br key={'break' + index} />;
        default:
          return null;
      }
    });
  };

  /**
   * Handles rendering the individual menu option
   *
   * @param {Object} menuItem the object data for the individual menu item
   * @param {string} menuName the name of the menu being accessed
   * @param {boolean} showMenuItem used to decide whether a item should be displayed
   * @param {boolean} hiddenByYear used to determine if the item should be hidden based on the tax year
   * @param {number} index the current index of the option
   * @param {boolean} hideMenuItem used to decide whether a item should be displayed
   * @returns
   */
  const renderMenuItem = (
    menuItem,
    menuName,
    showMenuItem,
    hiddenByYear,
    index,
    hideMenuItem = false,
  ) => {
    return (
      <MenuItem
        id={menuName + index}
        onClick={menuItem.func}
        className={`${classes.textItemStyles} ${isShortScreenRez && classes.isShortScreenRezStyle}`}
        disabled={!showMenuItem || hiddenByYear || hideMenuItem || !!lockedBy}
        key={'menuItem' + index + menuName}
      >
        <Typography type="body2" color="primary">
          {menuItem.text}
        </Typography>
      </MenuItem>
    );
  };

  /** Check if we are able to trigger the copy return live feature */
  const canCopyToLiveReturn = () => {
    return (
      props.isTrainingMode &&
      (['TORSC00', 'NAHMIC', 'AMAPRI00', 'JIMJUA', 'QATEST', 'DANJOS', 'CLOTST'].includes(
        payload?.account_code,
      ) ||
        ENVIRONMENT === 'local' ||
        ENVIRONMENT === 'development' ||
        ENVIRONMENT === 'QA')
    );
  };

  /** Handles checking each locked state to determine whether an overall lock should apply */
  const isLocked = () => {
    return lockedBy || lockedViaAccessLevel || lockedStatus || props.isSuperOrTechUserReadOnly();
  };

  /**
   * Keep track of active return nav at the top to prevent
   * multiple clicks to same nav causing the app to crash.
   */
  const handleReturnNav = (navItem, e) => {
    if (
      // This will fix the bug of needed to enter in a missing FLST if it has not already been added, and getting locked in the return
      navItem === 'save' &&
      (isLocked() || props.assetMode) &&
      !props.hasFLST()
    ) {
      // Will request user to enter a filing status if one has not been set yet
      props.handleRequireFilingStatusDlg();
      return;
    }

    let printFormName = 'prin17';

    switch (navItem) {
      case 'transmitReturn':
        if (isWizard) {
          props.handleWizardStep(9);
        }
        if (props.readyForReview) {
          // automatically retract from return when transmit button clicked
          props.retractReturnForReview();
        }
        props.setVerifyType('queueReturn');
        props.verifyReturn(VERIFY_MODAL_TABS.VERIFY);
        break;
      case 'notes':
        // User must be in formviewer section to view notes
        if (isWizard) {
          props.handleWizardStep(9);
        }
        props.toggleNotes();
        break;
      case 'returnPayments':
        props.toggleReturnPayment(true);
        break;
      case 'verifyReturn':
        if (isWizard) {
          props.handleWizardStep(9);
        }
        props.setVerifyType('verify');
        props.verifyReturn(VERIFY_MODAL_TABS.VERIFY);
        break;
      case 'interviewMode':
        props.showAddInterviewModal();
        break;
      case 'signatures':
        if (payload?.is_business) {
          printFormName = 'prin12';
        } else if (props.season >= 2020) {
          printFormName = 'prin18';
        }
        // User must be in formviewer section to view notes
        if (isWizard) {
          props.handleWizardStep(9);
        }
        props.handleRemoteSignSelected(printFormName);
        dispatch(returnProfileActions.setInitialForm(printFormName, ''));
        props.loadSignaturePage(printFormName);
        break;
      case 'print':
        handlePrintMenu(e.currentTarget);
        break;
      case 'mainMenu':
        handleMainMenu(e.currentTarget);
        break;
      case 'help':
        dispatch(appActions.toggleHelpModal());
        break;
      case 'save':
        props.assetMode ||
        lockedStatus ||
        lockedBy ||
        props.isSuperOrTechUserReadOnly() ||
        (officeProfile.is_feeder_office && props.readyForReview)
          ? closeReturn()
          : props.saveReturn();
        break;
    }
  };

  /**
   * Returns true if we are looking at return in feeder office, but the return is marked fo review by transmit office
   */
  const feederReviewLock = () => {
    return props.readyForReview && officeProfile.is_feeder_office;
  };

  /**
   * Returns true if return is a Wizard return but wizard portion is not yet completed
   */
  const wizardLock = () => {
    return !wizardCompleted && isWizard;
  };
  /**
   * Returns true if login does not have ability to edit returns. Will check to see if business returns are being viewed
   */
  const isViewReturnOnly = () => {
    if (payload?.is_business) {
      return !Object.hasOwn(activeAccessLevels, 'create_business_return');
    } else {
      return !Object.hasOwn(activeAccessLevels, 'add_return');
    }
  };

  /**
   * Handles recieving a input name to decide whether it should be disabled
   * consider running this one time on render, with one disable object, that the inputs pass in their keys to the values.
   *
   * @param {string} type name of the input that wants to be checked
   * @returns {Object} disabledObj contains a boolean used to decide whether an element is disabled, and the style to go with it.
   */
  const shouldDisable = type => {
    const disabledObj = {
      style: {},
      isDisabled: false,
    };

    // disable all header functionality if loading
    if (props.isLoading) disabledObj.isDisabled = true;

    switch (type) {
      case 'print':
        if (
          (props.assetMode && !state.isEFINAccount && lockedStatus) ||
          lockedBy ||
          wizardLock() ||
          props.activeForm === 'prin18' // we cannot run an additional return_print_query while already on the signatures page, this will fetch different singatures and we only want the '03' signatures for that page.
        ) {
          disabledObj.isDisabled = true;
        }
        break;
      case 'payments':
        if (
          (props.assetMode && !state.isEFINAccount && lockedStatus) ||
          lockedBy ||
          feederReviewLock() ||
          wizardLock() ||
          isSuperOrTechSupport(payload) ||
          isViewReturnOnly()
        ) {
          disabledObj.isDisabled = true;
        }
        break;
      case 'signatures':
        if (
          ((props.assetMode || state.hasNoPrintAccess) && !state.isEFINAccount && lockedStatus) ||
          isLocked() ||
          feederReviewLock() ||
          props.usingDemoAccount ||
          wizardLock()
        ) {
          disabledObj.isDisabled = true;
        }
        break;
      case 'notes':
      case 'interview':
      case 'menuItem':
        if (
          (state.hasNoPrintAccess && !state.isEFINAccount && lockedStatus) ||
          props.assetMode ||
          isLocked() ||
          feederReviewLock() ||
          wizardLock()
        ) {
          disabledObj.isDisabled = true;
        }
        break;
      case 'verify':
        if (
          ((props.assetMode || state.hasNoPrintAccess) && !state.isEFINAccount && lockedStatus) ||
          isLocked() ||
          wizardLock()
        ) {
          disabledObj.isDisabled = true;
        }
        break;
      case 'transmit':
        if (
          ((props.assetMode || state.hasNoPrintAccess) && !state.isEFINAccount && lockedStatus) ||
          (!officeProfile.has1040License && !officeProfile?.hasSubsequentLicense) ||
          officeProfile?.hasHold ||
          isLocked() ||
          areAgreementsSigned(agreementsStatus, payload?.season) ||
          feederReviewLock() ||
          wizardLock()
        ) {
          disabledObj.isDisabled = true;
        }
        break;
    }

    return disabledObj.isDisabled
      ? { ...disabledObj, style: { filter: 'grayscale(1)', cursor: 'unset' } }
      : disabledObj;
  };

  const headerBorder = props.assetMode
    ? {}
    : scrollPositionInit
    ? `
    ${classes.headerBarAppContainer} header-bar-shadow-none
    `
    : classes.headerBarAppContainer;

  return (
    <div className="header-bar-forms-toolbar-container">
      {(props.isTrainingMode || props.usingDemoAccount) && (
        <div className="training-mode">{`${props.usingDemoAccount ? 'DEMO VERSION  |' : ''}${
          props.isTrainingMode ? ' Training Mode |' : ''
        } Limited Functionality`}</div>
      )}
      <AppBar
        position="static"
        className={`${headerBorder} header-bar-shadow-none`}
        display="flex"
        align-items="center"
      >
        <Toolbar
          className={`${classes.headerBarFormsToolbar} ${
            props.assetMode ? '' : 'header-bar-shadow'
          }`}
          variant="dense"
        >
          <img
            id="clientNameInfo"
            className="header-bar-forms-flex-grow-one header-bar-forms-return-img"
            src={!isWizard ? contractJobIcon : magicWand}
          />

          <div className="col ovLabelVariable header-bar-forms-return-name-wrapper">
            <span className="header-bar-forms-return-label header-bar-forms-return-name">
              {state.returnTitle}
            </span>
            <span className="header-bar-forms-return-label header-bar-forms-return-id">
              ID: {friendlyID}
            </span>
          </div>
          <div className={classes.grow} />
          <div className="header-bar-forms-section-desktop">
            <IconButton
              id="returnNotesBtn"
              aria-label="returnNotesBtn"
              className={`profileLabelVariable ${props.notesAlert ? 'blinking' : ''}`}
              onClick={e => handleReturnNav('notes', e)}
              disabled={shouldDisable('notes').isDisabled}
            >
              <img
                title="Notes"
                id="returnNotesBtnIcon"
                className="noselect header-bar-forms-return-icon"
                src={notesIcon}
                style={shouldDisable('notes').style}
              />
            </IconButton>
            <label
              htmlFor="returnNotesBtn"
              className="noselect profileLabelVariable header-bar-forms-label-style"
              style={shouldDisable('notes').style}
            >
              Notes
            </label>
            <IconButton
              id="signaturesBtn"
              aria-label="signatures"
              className={`profileLabelVariable`}
              onClick={e => handleReturnNav('signatures', e)}
              disabled={shouldDisable('signatures').isDisabled}
            >
              <img
                id="signaturesIcon"
                className="header-bar-forms-return-icon"
                src={handSigningIcon}
                style={shouldDisable('signatures').style}
              />
            </IconButton>
            <label
              htmlFor="signaturesBtn"
              className="noselect profileLabelVariable header-bar-forms-label-style"
              style={shouldDisable('signatures').style}
            >
              Signatures
            </label>
            {!isWizard && !payload?.is_business ? (
              <>
                <IconButton
                  id="interviewModeBtn"
                  aria-label="interviewMode"
                  className={`profileLabelVariable`}
                  onClick={e => handleReturnNav('interviewMode', e)}
                  disabled={shouldDisable('signatures').isDisabled}
                >
                  <img
                    id="interviewModeIcon"
                    className="noselect header-bar-forms-return-icon"
                    src={interviewIcon}
                    style={shouldDisable('interview').style}
                  />
                </IconButton>
                <label
                  htmlFor="interviewModeBtn"
                  className="noselect profileLabelVariable header-bar-forms-label-style"
                  style={shouldDisable('interview').style}
                >
                  Interview
                </label>
              </>
            ) : null}
            <IconButton
              id="verifyReturnBtn"
              aria-label="verifyReturn"
              className={`profileLabelVariable`}
              onClick={e => handleReturnNav('verifyReturn', e)}
              disabled={shouldDisable('verify').isDisabled}
            >
              <VerifyCircle
                htmlFor="verifyReturnIcon"
                className="header-bar-forms-return-icon"
                style={shouldDisable('verify').style}
              />
            </IconButton>
            <label
              htmlFor="verifyReturnBtn"
              className="noselect profileLabelVariable header-bar-forms-label-style"
              style={shouldDisable('verify').style}
            >
              Verify Return
            </label>
            {!props.isTrainingMode && (
              <Tooltip
                title={
                  shouldDisable('transmit').isDisabled && !props.isLoading
                    ? 'This return is unavailable for transmit at this time. Please ensure you have available permissions to transmit.'
                    : ''
                }
              >
                <div className="header-bar-tooltip-container">
                  <IconButton
                    id="btnEfileReturn"
                    aria-label="btnEfileReturn"
                    className={`profileLabelVariable`}
                    onClick={e => handleReturnNav('transmitReturn', e)}
                    disabled={shouldDisable('transmit').isDisabled}
                  >
                    <img
                      id="transmitReturnIcon"
                      className="noselect header-bar-forms-return-icon"
                      src={queueIcon}
                      style={shouldDisable('transmit').style}
                    />
                  </IconButton>
                  <label
                    htmlFor="btnEfileReturn"
                    className="noselect profileLabelVariable header-bar-forms-label-style"
                    style={shouldDisable('transmit').style}
                  >
                    Transmit Return
                  </label>
                </div>
              </Tooltip>
            )}
            <Tooltip
              title={
                props.activeForm === 'prin18' // we cannot run an additional return_print_query while already on the signatures page, this will fetch different singatures and we only want the '03' signatures for that page.
                  ? 'Printing is not available while viewing the Signatures form. Please switch to a different form in order to print.'
                  : ''
              }
            >
              <div className="header-bar-tooltip-container">
                <AccessControl requiredAction="write" accessLevel="print_return">
                  <IconButton
                    id="printBtn"
                    aria-label="Print"
                    className={`profileLabelVariable`}
                    onClick={e => handleReturnNav('print', e)}
                    disabled={shouldDisable('print').isDisabled}
                  >
                    <img
                      id="printIcon"
                      className="header-bar-forms-return-icon"
                      src={printIcon}
                      style={shouldDisable('print').style}
                    />
                  </IconButton>
                  <label
                    htmlFor="printBtn"
                    className="noselect profileLabelVariable header-bar-forms-label-style"
                    style={shouldDisable('print').style}
                  >
                    Print
                  </label>
                  <Menu
                    id="printMenu"
                    anchorEl={state.anchorEl}
                    getContentAnchorEl={null}
                    className={classes.headerBarFormsReturnMenuList}
                    onClose={() => handleMenuClose('isPrintMenuOpen')}
                    open={state.isPrintMenuOpen}
                  >
                    {renderMenuItems('printOptions')}
                  </Menu>
                </AccessControl>
              </div>
            </Tooltip>
            {!props.isTrainingMode && (
              <>
                <IconButton
                  id="returnPaymentsBtn"
                  aria-label="returnPaymentsBtn"
                  className={`profileLabelVariable`}
                  onClick={e => handleReturnNav('returnPayments', e)}
                  disabled={shouldDisable('payments').isDisabled}
                >
                  <img
                    id="paymentClientIcon"
                    className="noselect header-bar-forms-return-icon"
                    src={paymentIcon}
                    style={shouldDisable('payments').style}
                  />
                </IconButton>
                <label
                  htmlFor="returnPaymentsBtn"
                  className="noselect profileLabelVariable header-bar-forms-label-style"
                  style={shouldDisable('payments').style}
                >
                  Payments
                </label>
              </>
            )}
            <IconButton
              id="helpClientBtn"
              aria-label="help"
              className={`profileLabelVariable`}
              onClick={e => handleReturnNav('help', e)}
              disabled={shouldDisable('help').isDisabled}
            >
              <img
                id="helpClientIcon"
                className="header-bar-forms-return-icon"
                src={questionIcon}
                style={shouldDisable('help').style}
              />
            </IconButton>
            <label
              htmlFor="helpClientBtn"
              className="noselect profileLabelVariable header-bar-forms-label-style"
              style={shouldDisable('help').style}
            >
              Help
            </label>
            <Modal
              className={classes.helpMenu}
              open={isHelpMenuVisible}
              onClose={() => dispatch(appActions.toggleHelpModal())}
              BackdropProps={{
                classes: {
                  root: classes.backDrop,
                },
              }}
            >
              <div>
                <HelpMenu
                  account_code={payload?.account_code}
                  hasRepublicBankApproval={state.hasRepublicBankApproval}
                />
              </div>
            </Modal>
            <div className={classes.MenuButton}>
              <Button
                id="btnSaveAndClose"
                variant="contained"
                size="medium"
                color="primary"
                className={classes.headerBarFormsHelpNavBtn}
                onClick={e => handleReturnNav('save', e)}
                disabled={props.isLoading || isViewReturnOnly()}
              >
                {props.assetMode ||
                lockedStatus ||
                lockedBy ||
                props.isSuperOrTechUserReadOnly() ||
                (officeProfile.is_feeder_office && props.readyForReview)
                  ? 'Close'
                  : 'Save'}
              </Button>
              {!props.isSuperOrTechUserReadOnly() && (
                <>
                  <Button
                    id="mainMenuBtn"
                    variant="contained"
                    color="primary"
                    className={`${classes.headerBarFormsReturnDropDownNav} ${
                      snackbarMessage.show ? 'blinking' : ''
                    }`}
                    onClick={e => handleReturnNav('mainMenu', e)}
                    disabled={props.isLoading}
                  >
                    <ArrowDropDown className="header-bar-forms-return-dropdown-arrow-white" />
                  </Button>

                  <Menu
                    id="mainMenuDropdown"
                    anchorEl={state.anchorEl}
                    getContentAnchorEl={null}
                    className={classes.headerBarFormsReturnMenuList}
                    onClose={() => handleMenuClose('isMainMenuOpen')}
                    open={state.isMainMenuOpen}
                  >
                    {renderMenuItems('menuOptions')}
                  </Menu>
                </>
              )}
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <SimpleDialog
        open={state.isMissingEROSignatureModalOpen}
        onConfirm={() => {
          setState({ isMissingEROSignatureModalOpen: false });
        }}
        confirmText="OK"
        styled={true}
        dialogTitle="ERO Signature Missing"
        contentText="This print functionality will not be enabled until the ERO created saves their signature. Ask the ERO to sign into the software and capture their signature using the 'Capture ERO Signature' link found in the Profile drop down menu."
      />
    </div>
  );
};

export default withStyles({ ...themeStyles, ...styles })(HeaderBarForms);
