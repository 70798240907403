// External imports
import axios from 'axios';
import jwtDecode from 'jwt-decode';
// Internal imports
import WebHelpers from '~/app/webHelpers.js';
import XlinkAPI from './xlinkAPI';
import { ACCESS_TOKEN, LOGIN_SETTINGS, REFRESH_TOKEN } from '~/app/constants.js';
// Redux imports
import { store } from '~/app/redux/index';
import { actions as appActions } from '~/app/redux/modules/app';

const CancelToken = axios.CancelToken;
// each request will generate it's own cancel function
const cancelFunctions = [];

export default class AuthAPI {
  static validateOrRefresh = fingerprint => {
    // use a different axios instance so to not interfer with the response interceptor and cause a loop
    const newAxiosInstance = axios.create();
    const payload = WebHelpers.getJWTPayload();

    return new Promise((resolve, reject) => {
      const jwt = sessionStorage.getItem(ACCESS_TOKEN);
      const refreshjwt = localStorage.getItem(REFRESH_TOKEN);
      if (!jwt) return resolve(false);
      // Check if TermsandConditions page needs to render or else T&C will be bypassed with a valid token on refresh
      if (sessionStorage.getItem(LOGIN_SETTINGS.SHOW_TERMS_AND_CONDITIONS) === 'true') {
        return resolve(false);
      }

      // retrieve the expiration field from the access_token
      const tokenExp = jwtDecode(jwt)?.exp;
      if (tokenExp <= 0) {
        return resolve(false);
      }

      const currentTime = new Date().getTime() / 1000;

      const handleRefresh = () => {
        // failed to validate, if the error is anything other than "expired" the refresh token should have been revoked
        if (!payload || !payload.identifier || payload.identifier === '') return resolve(false);
        newAxiosInstance
          .post(
            `${BASE_URL}/authn/refresh`,
            {
              refresh_token: refreshjwt,
              identifier: payload.identifier,
              fingerprint,
              season: payload.season,
              isBusiness: payload.is_business,
            },
            WebHelpers.jsonContentType,
          )
          .then(res => {
            sessionStorage.setItem(ACCESS_TOKEN, res.data.access_token); // 2nd Factor Authorized token via refresh
            localStorage.setItem(REFRESH_TOKEN, res.data.refresh_token);

            // retrieve the access_token expiration field and save it to the store
            const tokenExp = jwtDecode(res.data.access_token)?.exp;
            store.dispatch(appActions.updateRefreshTimer(tokenExp));

            return resolve(res);
          })
          .catch(error => {
            // Refresh token expired or invalid, will not grant access
            if (!error && !error.response) {
              WebHelpers.cleanupSession();
              WebHelpers.cleanupLocalStorage();
            }

            return reject(error);
          });
      };

      // If the expiration time is within 60 seconds, trigger refresh - skip validation request
      if (tokenExp - currentTime >= 0 && tokenExp - currentTime <= 60) {
        handleRefresh();

        // short circuit
      } else if (currentTime + 60 <= tokenExp) {
        return resolve(true);
      }

      // first, try to validate the existing access token
      newAxiosInstance
        .get(`${BASE_URL}/authz/validate`, WebHelpers.getAuthHeadersFor(jwt))
        .then(() => {
          return resolve(true);
        })
        .catch(() => {
          handleRefresh();
        });
    });
  };

  static toggleBusinessMode = isFeederOffice => {
    return new Promise((resolve, reject) => {
      const jwt = sessionStorage.getItem(ACCESS_TOKEN);
      if (!jwt) return resolve(false);

      const payload = WebHelpers.getJWTPayload();
      if (!payload || !payload.identifier || payload.identifier === '') return resolve(false);

      axios
        .post(
          `${BASE_URL}/authz/toggle-business-mode/${isFeederOffice}`,
          {},
          WebHelpers.getAuthHeaders(),
        )
        .then(res => {
          sessionStorage.setItem(ACCESS_TOKEN, res.data.access_token);

          // retrieve the access_token expiration field and save it to the store
          const tokenExp = jwtDecode(res.data.access_token)?.exp;
          store.dispatch(appActions.updateRefreshTimer(tokenExp));

          return resolve(true);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static submitCredentials = (username, password, fingerprint) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${BASE_URL}/authn/login`,
          {
            identifier: username,
            plaintext: password,
            fingerprint,
          },
          WebHelpers.jsonContentType,
        )
        .then(res => {
          if (res.data.password_status && res.data.password_status === 'expired') {
            return resolve(res.data);
          }
          const encodedToken = res.data.access_token;
          // ensure we can decode, store the encoded token
          const payload = WebHelpers.getJWTPayload(encodedToken);
          if (payload === '') {
            // eslint-disable-next-line prefer-promise-reject-errors
            return reject('Invalid payload');
          }
          if (payload.Identifier === '') {
            // eslint-disable-next-line prefer-promise-reject-errors
            return reject('Invalid JWT response');
          }
          sessionStorage.setItem(ACCESS_TOKEN, encodedToken);
          sessionStorage.setItem(LOGIN_SETTINGS.SHOW_IRS_MESSAGE, res.data.irsMessage);

          // retrieve the access_token expiration field and save it to the store
          const tokenExp = jwtDecode(res.data.access_token)?.exp;
          store.dispatch(appActions.updateRefreshTimer(tokenExp));

          // Terms and conditions should always come back on log in but just in case check it and set it on session storage
          let showTandC = true;
          if (res.data?.termsAndConditions) {
            showTandC = !res.data.termsAndConditions;
          }

          sessionStorage.setItem(LOGIN_SETTINGS.SHOW_TERMS_AND_CONDITIONS, showTandC);

          // Check if login is of type live or demo and redirect user accordingly
          if (res.data?.demoDomain && res.data?.liveDomain) {
            const href = window.location.href.replace('www.', '');
            // checking login name for demo indicator
            const isDemoLoginName = username.startsWith('demo-');
            const isDemoDomain = href.startsWith(res.data.demoDomain);

            if (isDemoLoginName && !isDemoDomain) {
              // Demo login attempting to log in at live domain
              alert('Please use Demo URL to log into demo accounts, browser will redirect');
              window.location.href = res.data.demoDomain;
              return resolve(false);
            } else if (!isDemoLoginName && isDemoDomain) {
              // Live login on the demo URL
              // non-demo login attemping to log in at demo(or other non-live) domain
              alert('Please use Live URL to log into non-demo accounts, browser will redirect');
              window.location.href = res.data.liveDomain;
              return resolve(false);
            } else if (!isDemoLoginName && !href.startsWith(res.data.liveDomain)) {
              // Live login on the wrong URL
              alert('You have navigated to an incorrect domain, browser will redirect');
              window.location.href = res.data.liveDomain;
              return resolve(false);
            }
          }

          return resolve(true);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static submitOTPToken = async (token, fingerprint, codeMethod) => {
    return new Promise((resolve, reject) => {
      const jwt = sessionStorage.getItem(ACCESS_TOKEN);
      axios
        .post(
          `${BASE_URL}/reg/authmfa`,
          {
            OTPToken: token,
            fingerprint,
            codeMethod,
          },
          WebHelpers.getAuthHeadersFor(jwt),
        )
        .then(res => {
          sessionStorage.setItem(ACCESS_TOKEN, res.data.access_token); // 2nd Factor Authorized token
          localStorage.setItem(REFRESH_TOKEN, res.data.refresh_token);

          // retrieve the access_token expiration field and save it to the store
          const tokenExp = jwtDecode(res.data.access_token)?.exp;
          store.dispatch(appActions.updateRefreshTimer(tokenExp));

          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  // temp deviceLabel  - just testing things
  static submitBypassMFA = async fingerprint => {
    return new Promise((resolve, reject) => {
      const jwt = sessionStorage.getItem(ACCESS_TOKEN);
      axios
        .post(
          `${BASE_URL}/reg/authbypassmfa`,
          {
            fingerprint,
          },
          WebHelpers.getAuthHeadersFor(jwt),
        )
        .then(res => {
          sessionStorage.setItem(ACCESS_TOKEN, res.data.access_token); // 2nd Factor Authorized token
          localStorage.setItem(REFRESH_TOKEN, res.data.refresh_token);

          // retrieve the access_token expiration field and save it to the store
          const tokenExp = jwtDecode(res.data.access_token)?.exp;
          store.dispatch(appActions.updateRefreshTimer(tokenExp));

          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static selectAnOfficeYear = async (efinID, season) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_URL}/authz/switchofficeyear`, `efin_id=${efinID}&season=${season}`, {
          ...WebHelpers.urlEncodedContentType(),
          ...WebHelpers.getAuthHeaders(),
        })
        .then(res => {
          // success, store the new access token
          sessionStorage.setItem(ACCESS_TOKEN, res.data.access_token);

          // retrieve the access_token expiration field and save it to the store
          const tokenExp = jwtDecode(res.data.access_token)?.exp;
          store.dispatch(appActions.updateRefreshTimer(tokenExp));

          resolve();
        })
        .catch(error => reject(error));
    });
  };

  static toggleTrainingMode = async () => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_URL}/authz/toggletrainingmode`, {}, WebHelpers.getAuthHeaders())
        .then(res => {
          sessionStorage.setItem(ACCESS_TOKEN, res.data.access_token);

          // retrieve the access_token expiration field and save it to the store
          const tokenExp = jwtDecode(res.data.access_token)?.exp;
          store.dispatch(appActions.updateRefreshTimer(tokenExp));

          resolve();
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static impersonateLogin = async identifier => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_URL}/authz/impersonate`, { identifier }, WebHelpers.getAuthHeaders())
        .then(res => {
          sessionStorage.setItem(ACCESS_TOKEN, res.data.access_token);

          // retrieve the access_token expiration field and save it to the store
          const tokenExp = jwtDecode(res.data.access_token)?.exp;
          store.dispatch(appActions.updateRefreshTimer(tokenExp));

          resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static sendCodeToEmail = username => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${BASE_URL}/authn/forgotpassword`,
          { identifier: username },
          WebHelpers.getAuthHeaders(),
        )
        .then(resp => {
          if (resp) {
            resolve(resp);
          } else {
            resolve();
          }
        })
        .catch(error => reject(error));
    });
  };

  static sendUsernameToEmail = email => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_URL}/authn/forgotusername`, { email }, WebHelpers.getAuthHeaders())
        .then(() => resolve())
        .catch(error => reject(error));
    });
  };

  static resetPassword = (username, code, plaintext) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${BASE_URL}/authn/resetpasswordwithcode`,
          {
            identifier: username,
            code,
            plaintext,
          },
          WebHelpers.getAuthHeaders(),
        )
        .then(res => resolve(res))
        .catch(error => reject(error));
    });
  };

  static changePassword = (oldpassword, newpassword) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${BASE_URL}/authz/resetpassword`,
          {
            oldpassword,
            password: newpassword,
          },
          WebHelpers.getAuthHeaders(),
        )
        .then(res => resolve(res))
        .catch(error => reject(error));
    });
  };

  static logout = async () => {
    await XlinkAPI.cancelAllRequests();
    await XlinkAPI.clearMyLocks();
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_URL}/authz/invalidate`, WebHelpers.getAuthHeaders())
        .then(() => {
          WebHelpers.cleanupSession();
          WebHelpers.cleanupLocalStorage();
          resolve();
        })
        // eslint-disable-next-line prefer-promise-reject-errors
        .catch(() => reject());
    });
  };

  static verifyReCaptcha = token => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_URL}/authn/verifyrecaptcha/${token}`, WebHelpers.getAuthHeaders())
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static registerAccount = (account, accountType, loginID) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${BASE_URL}/authz/register/${accountType}`,
          { account, drilled_login: loginID },
          WebHelpers.getAuthHeaders(),
        )
        .then(() => resolve())
        .catch(error => reject(error));
    });
  };

  static registerAccountPassword = (verifyCode, newPassword, confirmPassword) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${BASE_URL}/authn/registerAccount`,
          {
            verifyCode,
            newPassword,
            confirmPassword,
          },
          WebHelpers.getAuthHeaders(),
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static updateAccountActivationlink = verifyCode => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_URL}/authn/updateActivationLink`, { verifyCode }, WebHelpers.getAuthHeaders())
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  /*
    static checkiflinkexpired = (verifyCode) => {
        console.log("Called checkiflinkexpired on AUTHAPI")
        return new Promise((resolve, reject) => {
            axios.post(`${BASE_URL}/authn/checkLinkExpiration`, { "verifyCode": verifyCode},
                WebHelpers.getAuthHeaders())
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return reject(error)
                });
        })
    }
    */

  static checkiflinkexpired = verifyCode => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_URL}/authn/checkLinkExpiration/${verifyCode}`, WebHelpers.getAuthHeaders())
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static getUsernameFromLink = verifyCode => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_URL}/authn/getUsernameFromLink/${verifyCode}`, WebHelpers.getAuthHeaders())
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static getQRCode = verifyCode => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_URL}/authn/mfatemp`, { verifyCode }, WebHelpers.getAuthHeaders())
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static generateNewQRCode = password => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_URL}/authz/mfa/generate/qrcode`, { password }, WebHelpers.getAuthHeaders())
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static resendAccountActivationLink = (accountID, sendLoginID, loginID, email) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${BASE_URL}/authz/resend`,
          {
            account: accountID,
            send_to_login: sendLoginID,
            drilled_login: loginID,
            email,
          },
          WebHelpers.getAuthHeaders(),
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static verifyRemoteDocRequestAuth = (
    name,
    lastFourSSNEIN,
    dob,
    requesteeType,
    authorizationCode,
    zipCode,
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${BASE_URL}/authn/VerifyRemoteDocRequest`,
          {
            params: {
              name,
              ssnEIN: lastFourSSNEIN,
              dob,
              requesteeType,
              authorizationCode,
              zipCode,
            },
          },
          WebHelpers.getAuthHeaders(),
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static VerifyPreparerRemoteSignature = (name, lastFourPTIN, requesteeType, authorizationCode) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${BASE_URL}/authn/VerifyPreparerRemoteSignature`,
          {
            params: {
              name,
              lastFourPTIN,
              requesteeType,
              authorizationCode,
            },
          },
          WebHelpers.getAuthHeaders(),
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  // Get signature for preparer from a public route
  static getSignatureForPreparer = preparerID => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_URL}/authn/preparer/signature/${preparerID}`, {}, WebHelpers.getAuthHeaders())
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  // signatureString is a string containing base 64 data
  static postSignature = async (
    newSignature,
    preparerID,
    hierachyTypeID,
    signeeTypeID,
    signaturePadTypeID,
    remoteSignatureRequestID,
    createdBy,
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${BASE_URL}/authn/preparer/signature`,
          {
            newSignature,
            preparerID,
            hierachyTypeID,
            signeeTypeID,
            signaturePadTypeID,
            remoteSignatureRequestID,
            createdBy,
          },
          WebHelpers.getAuthHeaders(),
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static CheckRemoteSignAuthData = (
    paramLastName,
    paramDOB,
    paramLastFour,
    paramZipCode,
    paramCodeRemoteSign,
    requesteeType,
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${BASE_URL}/authn/verifyRemoteSignAuthData`,
          {
            paramLastName,
            paramDOB,
            paramLastFour,
            paramZipCode,
            paramCodeRemoteSign,
            requesteeType,
          },
          WebHelpers.getAuthHeaders(),
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  // CheckRemoteSignAuthDataPrepEROBusiness
  static CheckRemoteSignAuthDataPrepEROBusiness = (
    lastName,
    lastFourPrimary,
    codeRemoteSign,
    signeeType,
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${BASE_URL}/authn/verifyRemoteSignAuthDataPrepEROBusiness/${lastName}/${lastFourPrimary}/${signeeType}/${codeRemoteSign}`,
          WebHelpers.getAuthHeaders(),
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  // AuthAPI.PostCodeRemoteSign(documentID, typeID, signatureEmail, signatureID)
  static PostCodeRemoteSign = (sigDocID, typeID, signatureEmail, signatureValidationID) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${BASE_URL}/authn/postremotesignaturecode`,
          {
            sigDocID,
            typeID,
            signatureEmail,
            signatureValidationID,
          },
          WebHelpers.getAuthHeaders(),
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static postRemoteDocumentData = (remoteDocID, fileData, description, isBusiness = false) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${BASE_URL}/authn/UploadRemoteDocument/${remoteDocID}`,
          {
            file: fileData.file,
            fileName: fileData.fileName,
            fileType: fileData.fileType,
            fileSize: fileData.fileSize,
            description,
            isBusiness,
          },
          WebHelpers.getAuthHeaders(),
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static PostSignedSignatureData = async (
    sigDocID,
    signatureValidationID,
    categoryID,
    typeID,
    remoteSignatureData,
    signatureToken,
    electronicDisclosureConsent,
    season,
  ) => {
    return await axios.post(
      `${BASE_URL}/authn/signatures/${sigDocID}`,
      {
        signatureValidationID,
        categoryID,
        typeID,
        remoteSignatureData,
        signatureToken,
        electronicDisclosureConsent,
        season,
      },
      {
        ...WebHelpers.getAuthHeaders(),
        cancelToken: new CancelToken(fn => {
          cancelFunctions.push(fn);
        }),
      },
    );
  };

  static UpsertAppVersion = version => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_URL}/pub/upsertappversion`, version, WebHelpers.getAuthHeaders())
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static getAppVersion = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_URL}/pub/getappversion`, {
          ...WebHelpers.getAuthHeaders(),
        })
        .then(resp => resolve(resp))
        .catch(error => reject(error));
    });
  };

  // GetSignatureBankInfo gets the bank information for remote signature signing.
  static GetSignatureBankInfo = async (sigDocID, season) => {
    return await axios.get(
      `${BASE_URL}/authn/signatures/${sigDocID}`,
      {
        params: { season },
      },
      {
        ...WebHelpers.getAuthHeaders(),
        cancelToken: new CancelToken(c => {
          cancelFunctions.push(c);
        }),
      },
    );
  };

  static IsRMSAuthCodeActive = async (verifyCode, signeeType) => {
    return await axios.get(
      `${BASE_URL}/authn/RMS/verify-auth-code/${signeeType}/${verifyCode}`,
      {},
      {
        ...WebHelpers.getAuthHeaders(),
        cancelToken: new CancelToken(c => {
          cancelFunctions.push(c);
        }),
      },
    );
  };
}
