import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { copyToClipboard } from '~/app/Utility/general';

export const displayTaxPassAppUrl = mobileAppID => {
  // Leaving for now as they may come back wanting to be excluded.
  /* const accountArray = [
    'RIVEDG',
    'WILMON00',
    'RIVEDG27',
    'RIVEDG29',
    'RIVEDG42',
    'RIVEDG62',
    'RIVEDG85',
  ];

  if (!accountArray.includes(payload.account_code)) { */
  return (
    <div
      className="taxpass-url-container link-text"
      onClick={() => copyToClipboard(`${TAXPASS_URL}/?taxpassid=${mobileAppID}`)}
    >
      <span className="taxpass-url-text">
        Mobile Site URL <FontAwesomeIcon icon={faCopy} />
      </span>
    </div>
  );
  /* }
  return <></>; */
};
