// External imports
import React, { useEffect } from 'react';
import {
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputLabel,
  withStyles,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
// Internal imports
import AccessControl from '#/Auth/AccessControl.jsx';
import { useSetState } from '~/app/Utility/customHooks.js';
import { determineBankIDByName } from '~/app/Utility/general.js';
import { formatDollarAmt } from '~/app/Pages/Payments/helpers/paymentHelpers.js';
import { RAC_BANK } from '~/app/constants.js';
// Styling imports
import { styles } from '~/app/Components/NewAccount/SingleOffice/css/formBilling.js';

/**
 * EFIN Fees change based on the selected bank and season
 *
 * @component EfinFees
 * @catergory GenBillingSetup
 */
const EfinFees = props => {
  const { classes } = props;
  const [state, setState] = useSetState({
    expanded: true,
    efinfees: props.feesInfo,
    currentBankID: 0,
  });

  useEffect(() => {
    setState({ currentBankID: determineBankIDByName(state.efinfees.bank) });
  }, [state.efinfees.bank]);

  // TESTING: update `bank` to whatever bank you want to test the UI for. Check the constant `RAC_BANK` for possible banks
  // useEffect(() => {
  //   setState({
  //     efinfees: {
  //       ...state.efinfees,
  //       bank: 'Refund Advantage',
  //     },
  //   });
  // }, []);

  const handleExpandClick = () => {
    setState(state => ({ expanded: !state.expanded }));
  };

  // sbFee and ssbFee are combined
  const sbFee = parseInt(state.efinfees.sbFee.replace(/[$,.]/g, '')) || 0;
  const ssbFee = parseInt(state.efinfees.ssbFee.replace(/[$,.]/g, '')) || 0;
  const combinedSBFees = sbFee + ssbFee;

  // The central site code is repurposed in 2025 for Republic bank and for season 2025 and further. Refund Advantage also prefixes its name to the label
  const cardProgramOrDecemberDollarsLabel =
    props.season >= 2025 && state.currentBankID === RAC_BANK.REPUBLIC_BANK
      ? 'December Dollars'
      : `${
          state.currentBankID === RAC_BANK.REFUND_ADVANTAGE ? 'Refund Advantage ' : ''
        }Card Program`;

  // Please note that UI changes based on Select Bank and season
  return (
    <div className="container-fixed" style={{ paddingBottom: '3vh' }}>
      <div style={{ textAlign: 'left', paddingTop: '3vh', paddingBottom: '2vh' }}>
        <div style={{ float: 'left' }}>
          <IconButton
            id="icobtnExpandCustomChanges"
            style={{ width: '1em', height: '1em' }}
            onClick={handleExpandClick}
            aria-expanded={state.expanded}
            classes={{ root: classes.noBorderOnFocus }}
          >
            {state.expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </div>
        <div>
          <span
            style={{
              fontFamily: 'Roboto',
              fontSize: '21px',
              letterSpacing: '0.14px',
              color: '#1A173B',
              marginLeft: '0.3em',
            }}
          >
            EFIN Fees
            <IconButton
              className={classes.iconButton}
              id="icobtnInfoEfinInfo"
              color="primary"
              aria-label="info"
              onClick={() => props.openInfoDialog(props.itemType)}
            >
              <InfoOutlinedIcon />
            </IconButton>
          </span>
        </div>
      </div>
      <Collapse in={state.expanded} timeout="auto" unmountOnExit>
        <AccessControl requiredAction="write" accessLevel="add/edit_billing" disableOnFalse={true}>
          <Grid container>
            {/* Left Column */}
            <Grid item xs={6}>
              <Grid container spacing={8}>
                {/* Non-BP does not display the entire right side. If this is the case we should move EFIN year to the left side for cleaner UI */}
                {state.currentBankID === RAC_BANK.NON_BP && (
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel
                        classes={{ root: classes.labelWidthStyle }}
                        htmlFor="txtSeason"
                        shrink
                      >
                        EFIN Year
                      </InputLabel>
                      <Input
                        id="txtSeason"
                        name="season"
                        value={props.season}
                        style={{ width: '20vw' }}
                        disableUnderline
                        disabled={true}
                      />
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel
                      classes={{ root: classes.labelWidthStyle }}
                      htmlFor="txtTranBaseFee"
                      shrink
                    >
                      Transmitter Base Fee
                    </InputLabel>
                    <Input
                      id="txtTranBaseFee"
                      name="tranBaseFee"
                      value={state.efinfees.tranBaseFee}
                      style={{ width: '20vw' }}
                      disableUnderline
                      disabled={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel
                      classes={{ root: classes.labelWidthStyle }}
                      htmlFor="txtTranAddOnFee"
                      shrink
                    >
                      Transmitter Add-on Fee
                    </InputLabel>
                    <Input
                      id="txtTranAddOnFee"
                      name="tranAddOnFee"
                      value={state.efinfees.tranAddOnFee}
                      style={{ width: '20vw' }}
                      disableUnderline
                      disabled={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel
                      classes={{ root: classes.labelWidthStyle }}
                      htmlFor="txtSBFee"
                      shrink
                    >
                      Service Bureau Fee
                    </InputLabel>
                    <Input
                      id="txtSBFee"
                      name="sbFee"
                      value={formatDollarAmt(combinedSBFees, true)}
                      style={{ width: '20vw' }}
                      disableUnderline
                      disabled={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel
                      classes={{ root: classes.labelWidthStyle }}
                      htmlFor="txtSBName"
                      shrink
                    >
                      Service Bureau Name
                    </InputLabel>
                    <Input
                      id="txtSBName"
                      name="sbName"
                      value={state.efinfees.sbName}
                      style={{ width: '20vw' }}
                      disableUnderline
                      disabled={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel
                      classes={{ root: classes.labelWidthStyle }}
                      htmlFor="txtSFTechFee"
                      shrink
                    >
                      Software Technology Fee
                    </InputLabel>
                    <Input
                      id="txtSFTechFee"
                      name="sfTechFee"
                      value={state.efinfees.sfTechFee}
                      style={{ width: '20vw' }}
                      disableUnderline
                      disabled={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel
                      classes={{ root: classes.labelWidthStyle }}
                      htmlFor="txtSfTechAddonFee"
                      shrink
                    >
                      Software Technology Add-on Fee
                    </InputLabel>
                    <Input
                      id="txtSfTechAddonFee"
                      name="sfTechAddonFee"
                      value={state.efinfees.sfTechAddonFee}
                      style={{ width: '20vw' }}
                      disableUnderline
                      disabled={true}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel
                    classes={{ root: classes.labelWidthStyle }}
                    htmlFor="txtElectronicFilingAddOnFee"
                    shrink
                  >
                    Electronic Filing Add-on Fee
                  </InputLabel>
                  <Input
                    id="txtElectronicFilingAddOnFee"
                    name="efileFee"
                    value={state.efinfees.efileFee}
                    style={{ width: '20vw' }}
                    disableUnderline
                    disabled={true}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormControlLabel
                    label="Software Technology Fee on All Products"
                    disabled
                    control={
                      <Checkbox
                        id="chkTechFeeAllProd"
                        checked={state.efinfees.techFeeOnAllProd}
                        color="primary"
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fontSize: 24, color: '#8FC3FF' }}
                            color="secondary"
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                      />
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormControlLabel
                    label="Electronic Filing Fee on All Products"
                    disabled
                    control={
                      <Checkbox
                        id="chkElectronicFilingFeeOnAllProducts"
                        checked={state.efinfees.efileFeeOnAllProd}
                        color="primary"
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fontSize: 24, color: '#8FC3FF' }}
                            color="secondary"
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                      />
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormControlLabel
                    label="Service Bureau Fee on All Products"
                    disabled
                    control={
                      <Checkbox
                        id="chksbFeeOnAll"
                        checked={state.efinfees.chksbFeeOnAll}
                        color="primary"
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fontSize: 24, color: '#8FC3FF' }}
                            color="secondary"
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                      />
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            {/* Right Column */}
            <Grid item xs={6}>
              <Grid container spacing={8}>
                {/* Non-BP does not use these fields and Desktop does not display them */}
                {state.currentBankID !== RAC_BANK.NON_BP && (
                  <>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel
                          classes={{ root: classes.labelWidthStyle }}
                          htmlFor="txtSeason"
                          shrink
                        >
                          EFIN Year
                        </InputLabel>
                        <Input
                          id="txtSeason"
                          name="season"
                          value={props.season}
                          style={{ width: '20vw' }}
                          disableUnderline
                          disabled={true}
                        />
                      </FormControl>
                    </Grid>
                    {/* Refund Advantage does not use a Doc Prep Fee, and instead uses an additional EF Fee */}
                    {state.currentBankID !== RAC_BANK.REFUND_ADVANTAGE ? (
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel
                            classes={{ root: classes.labelWidthStyle }}
                            htmlFor="txtDocPrepFee"
                            shrink
                          >
                            {/* Doc Prep Fee shares the same central site code for all banks `DFEE` - based on the bank indicator is what bank name we should display here for Doc Prep Fee */}
                            {`${
                              state.efinfees.bank !== '' ? state.efinfees.bank + ' ' : ''
                            }Doc Prep Fee`}
                          </InputLabel>
                          <Input
                            id="txtDocPrepFee"
                            name="docPrepFee"
                            value={state.efinfees.docPrepFee}
                            style={{ width: '20vw' }}
                            disableUnderline
                            disabled={true}
                          />
                        </FormControl>
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel
                            classes={{ root: classes.labelWidthStyle }}
                            htmlFor="txtRefundAdvantageEFFee"
                            shrink
                          >
                            Refund Advantage EF Fee
                          </InputLabel>
                          <Input
                            id="txtRefundAdvantageEFFee"
                            name="refundAdvantageEFFee"
                            value={state.efinfees.refundAdvantageEFFee}
                            style={{ width: '20vw' }}
                            disableUnderline
                            disabled={true}
                          />
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel
                          classes={{ root: classes.labelWidthStyle }}
                          htmlFor="txtBankName"
                          shrink
                        >
                          Bank Name
                        </InputLabel>
                        <Input
                          id="txtBankName"
                          name="bankName"
                          value={state.efinfees.bank}
                          style={{ width: '20vw' }}
                          disableUnderline
                          disabled={true}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel
                          classes={{ root: classes.labelWidthStyle }}
                          htmlFor="txtBankAppStatus"
                          shrink
                        >
                          Bank Product Indicator
                        </InputLabel>
                        <Input
                          id="txtBankAppStatus"
                          name="bankAppStatus"
                          value={state.efinfees.bankAppStatus}
                          style={{ width: '20vw' }}
                          disableUnderline
                          disabled={true}
                        />
                      </FormControl>
                    </Grid>
                    {/* Only Refundo uses these fields */}
                    {state.currentBankID === RAC_BANK.REFUNDO && (
                      <>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <InputLabel
                              classes={{ root: classes.labelWidthStyle }}
                              htmlFor="txtRefundoRoll"
                              shrink
                            >
                              Refundo Roll
                            </InputLabel>
                            <Input
                              id="txtRefundoRoll"
                              name="refundoRoll"
                              value={state.efinfees.refundoRoll}
                              style={{ width: '20vw' }}
                              disableUnderline
                              disabled={true}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <InputLabel
                              classes={{ root: classes.labelWidthStyle }}
                              htmlFor="txtRefundoPreAuthDPI"
                              shrink
                            >
                              Refundo Pre Auth DPI
                            </InputLabel>
                            <Input
                              id="txtRefundoPreAuthDPI"
                              name="refundoPreAuthDPI"
                              value={state.efinfees.refundoPreAuthDPI}
                              style={{ width: '20vw' }}
                              disableUnderline
                              disabled={true}
                            />
                          </FormControl>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel
                          classes={{ root: classes.labelWidthStyle }}
                          htmlFor="txtRaCardProgram"
                          shrink
                        >
                          {cardProgramOrDecemberDollarsLabel}
                        </InputLabel>
                        <Input
                          id="txtRaCardProgram"
                          name="raCardProgram"
                          value={state.efinfees.raCardProgram}
                          style={{ width: '20vw' }}
                          disableUnderline
                          disabled={true}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel
                          classes={{ root: classes.labelWidthStyle }}
                          htmlFor="txtPreAckEasyAdvance"
                          shrink
                        >
                          Pre Ack Easy Advance
                        </InputLabel>
                        <Input
                          id="txtPreAckEasyAdvance"
                          name="preAckAdvance"
                          value={state.efinfees.preAckAdvance}
                          style={{ width: '20vw' }}
                          disableUnderline
                          disabled={true}
                        />
                      </FormControl>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </AccessControl>
      </Collapse>
    </div>
  );
};

export default withStyles(styles)(EfinFees);
