// External imports
import React, { useEffect, useState, Fragment } from 'react';
import get from 'lodash-es/get';
import {
  Grid,
  AppBar,
  Toolbar,
  Button,
  withStyles,
  Tooltip,
  TextField,
  IconButton,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
// Internal imports
import XlinkAPI from '~/app/api/xlinkAPI';
import Message from './Message.jsx';
// Redux imports
import { useDispatch } from 'react-redux';
import { actions as formViewerActions } from '~/app/redux/modules/formViewer';
// Styling imports
import { styles } from './css/messages.js';

const TaxpassMessageModal = props => {
  const { classes } = props;
  const dispatch = useDispatch();
  const [messageList, setMessageList] = useState([]);
  const [sendMessageValue, setSendMessageValue] = useState('');

  useEffect(() => {
    // Scroll to the bottom of the message list for the latest message
    const modal = document.getElementById('taxpass-modal');
    const modalContent = modal.querySelector('.taxpass-msg-container');
    modalContent.scrollTop = modalContent.scrollHeight;
  }, [messageList]);

  useEffect(() => {
    getMessages(props.returnID);
  }, [props.refreshTaxpassMessageFlag]);

  const getMessages = async returnID => {
    try {
      const res = await XlinkAPI.getTaxpassMessagesByReturnID(returnID);
      setMessageList(get(res.data, 'messages', []) || []); // '... || []' handles null values.
    } catch (error) {
      dispatch(formViewerActions.showErrorDialog('Error fetching Mobile Site messages'));
    }
  };

  const renderMessageList = () => {
    let lastDate = ''; // YYYYMMDD date

    return messageList.map((message, idx) => {
      message.messageTimestamp = moment(message.messageTimestamp).local();
      const date = message.messageTimestamp.format('YYYY-DD-MM');
      const time = message.messageTimestamp.format('hh:mm A');

      const messageRender = (
        <Message
          message={message.messageText}
          isReceiver={message.sentFromERO}
          timestamp={time}
          isLast={message.isLastMessageOfType}
          key={`message-${idx}`}
        />
      );
      if (lastDate !== date) {
        lastDate = date;
        return (
          <Fragment key={`message-${idx}`}>
            <div className={'date-separator-timestamp'}>{lastDate}</div>
            {messageRender}
          </Fragment>
        );
      } else {
        return messageRender;
      }
    });
  };

  return (
    <div id="taxpass-modal">
      <AppBar position="static">
        <Toolbar className="textlink-received-modal-toolbar">
          Mobile Messages
          <Tooltip title="Messages are not sent/received in real time. Mobile site messages displayed here may not reflect the actual order of messages.">
            <InfoIcon fontSize="small" />
          </Tooltip>
          <IconButton className={classes.closeIcon} aria-label="Close" onClick={props.closeModal}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Grid container alignItems="center" direction="row" justify="space-around" spacing={4}>
        <Grid item xs={12} className="textlink-received-modal-grid">
          <div className="taxpass-msg-container">{renderMessageList()}</div>
        </Grid>
        <Grid item xs={9}>
          <TextField
            id="txtFieldMultiLineTaxPassMessage"
            multiline
            rowsMax={2}
            value={sendMessageValue}
            onChange={e => {
              setSendMessageValue(e.target.value);
            }}
            className={classes.textField}
            InputProps={{ disableUnderline: true }}
            placeholder="Message goes here"
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            disabled={sendMessageValue.length < 1}
            onClick={() => {
              props.handleSendTaxpassMessage(sendMessageValue);
              setSendMessageValue('');
            }}
          >
            Send
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(TaxpassMessageModal);
