// External imports
import React, { useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import {
  Button,
  Divider,
  FormControl,
  Input,
  MenuItem,
  Select,
  withStyles,
} from '@material-ui/core';
// Internal imports
import AuthAPI from '~/app/api/authAPI.js';
import XlinkAPI from '~/app/api/xlinkAPI';
import ErrorHelpers from '~/app/errorHelpers.js';
import {
  ROLE_TYPE,
  ACCOUNTSEARCH,
  OFFICESEARCH,
  PREPARERSEARCH,
  RETURNSEARCH,
  CORPRETURNSEARCH,
  EFINSEARCH,
} from '~/app/constants.js';
import { canAccessCorp } from '~/app/Utility/general';
import WebHelpers, { statusOK } from '~/app/webHelpers.js';
import { useSetState } from '~/app/Utility/customHooks';
// Redux imports
import { useDispatch, useSelector } from 'react-redux';
import { actions as appActions } from '~/app/redux/modules/app';
import { actions as searchActions } from '~/app/redux/search/duck';
import { actions as returnProfileActions } from '~/app/redux/returnProfile/duck';
// Styling imports
import { styles } from '~/app/Components/HeaderBar/components/SearchBar/css/searchBar.js';
// Images
import magicWand from '~/images/icons/icons8-magic-wand.svg';

const initialState = {
  placeholderText: 'Search',
  dropdownContent: [],
  searchCriteria: '',
  searchValue: '',
  searchResults: [],
  activeEfinID: 0,
  offices: [],
};

const SearchBar = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const payload = WebHelpers.getJWTPayload();
  const { classes } = props;
  const { officeProfile, currentView, isSearchBarVisible } = useSelector(state => ({
    officeProfile: state.officeProfile,
    currentView: state.drilldown.drilldownHistory[state.drilldown.drilldownHistory.length - 1],
    isSearchBarVisible: state.app.isSearchBarVisible,
  }));
  const [state, setState] = useSetState({
    ...initialState,
  });

  useEffect(() => {
    if (!isSearchBarVisible) {
      setState({ ...initialState });
    } else {
      updateDropdownDataForRole();
    }
  }, [isSearchBarVisible]);

  const getInitialPlaceholderText = () => {
    let placeholder = 'Search';

    switch (currentView?.role) {
      case ROLE_TYPE.SUPERUSER:
      case ROLE_TYPE.RESELLER:
        placeholder = ACCOUNTSEARCH;
        break;
      case ROLE_TYPE.MULTIOFFICE:
        placeholder = OFFICESEARCH;
        break;
      case ROLE_TYPE.EFIN:
        placeholder = RETURNSEARCH;
        break;
      case ROLE_TYPE.PREPARER:
        placeholder = RETURNSEARCH;
        break;
      case ROLE_TYPE.TECH_SUPPORT:
    }
    setState({ placeholderText: placeholder });
  };

  const handleFilterChange = e => {
    let placeholder;
    switch (e.target.value) {
      case 'account':
        placeholder = ACCOUNTSEARCH;
        break;
      case 'efin':
        placeholder = EFINSEARCH;
        break;
      case 'office':
        placeholder = OFFICESEARCH;
        break;
      case 'preparer':
        placeholder = PREPARERSEARCH;
        break;
      case 'return':
      case 'training':
        placeholder = RETURNSEARCH;
        break;
      case 'returncorp':
        placeholder = CORPRETURNSEARCH;
        break;
    }
    setState({
      searchCriteria: e.target.value,
      searchValue: '',
      searchResults: [],
      placeholderText: placeholder,
    });
  };

  const handleSearchValueChange = e => {
    /*
    Disabling enter key press for now since it brings up
    the Search Results page which hasn't been fully implemented.
    */
    if (e.key === 'Enter') {
      // handle 'enter' in handleEnterKeyPress()
      return;
    }

    const prefix = e.target.value.toUpperCase();
    setState({ searchValue: prefix });
    debounceSearch(prefix);
  };

  // Wait unitl user pauses typing to call to API
  const debounceSearch = _.debounce(value => {
    getSearchSuggestions(value);
  }, 250); // Doherthy Threshold < 400ms

  const onClickSearchResult = async resultIdx => {
    const result = state.searchResults[resultIdx];

    dispatch(appActions.toggleSearchBar(false));

    if (result.hierarchy_type_id === ROLE_TYPE.RESELLER) {
      history.push({ pathname: '/dashboard' });
      buildBreadcrumbs(
        currentView?.loginID,
        0,
        result.id,
        result.hierarchy_type_id,
        result.text,
        0,
      );
    } else if (result.hierarchy_type_id === ROLE_TYPE.MULTIOFFICE) {
      history.push({ pathname: '/dashboard' });
      buildBreadcrumbs(
        currentView?.loginID,
        0,
        result.id,
        result.hierarchy_type_id,
        result.text,
        0,
      );
    } else if (result.hierarchy_type_id === ROLE_TYPE.EFIN) {
      history.push({ pathname: '/dashboard' });
      buildBreadcrumbs(
        currentView?.loginID,
        0,
        result.id,
        result.hierarchy_type_id,
        result.text,
        0,
      );
    } else if (result.hierarchy_type_id === ROLE_TYPE.PREPARER) {
      // if an account is drilling into a preparer, let them choose which returns to view
      // for the preparer if the preparer is associated with more than one office in this org
      XlinkAPI.getOfficesForPreparer(result.id)
        .then(res => {
          if (res?.data?.length > 1) {
            // show office list modal to get efinID of office
            const efinID = res.data[0].efin_id; // use first office for testing
            // let efinID = state.activeEfinID

            buildBreadcrumbs(
              currentView?.loginID,
              currentView?.preparerID,
              result.id,
              ROLE_TYPE.PREPARER,
              result.text,
              efinID,
            );
          } else {
            buildBreadcrumbs(
              currentView?.loginID,
              currentView?.preparerID,
              result.id,
              ROLE_TYPE.PREPARER,
              result.text,
              res.data[0].efin_id,
            );
          }
        })
        .catch(error => {
          ErrorHelpers.handleError('Fetch Error', error);
        });
    } else {
      // Returns
      try {
        if (payload?.is_business !== result?.isBusinessRtn) {
          await AuthAPI.toggleBusinessMode(officeProfile.is_feeder_office);
        }
        showReturnProfile(result.id);
        history.push({
          pathname: '/tax-return-profile',
        });
      } catch (error) {
        ErrorHelpers.handleError('Error toggling business mode', error);
      }
    }
  };

  const buildBreadcrumbs = (parentID, preparerID, leafID, leafType, leafName, efinID) => {
    if (preparerID && preparerID === 0) {
      // don't need this endpoint for preparers, there is no path to complete between preparer and return
      return;
    }

    dispatch(
      searchActions.fetchBreadcrumbs(parentID, preparerID, leafID, leafType, leafName, efinID),
    );
  };

  const showReturnProfile = returnID => {
    dispatch(returnProfileActions.setReturnProfileReturnID(returnID));
    dispatch(returnProfileActions.fetchReturnProfileByReturnID(returnID));
  };

  const getSearchSuggestions = async prefix => {
    if (prefix != null && prefix.length > 0) {
      const criteria = state.searchCriteria;
      let roleGroup;
      let id;

      switch (currentView?.role) {
        case ROLE_TYPE.SUPERUSER:
          roleGroup = 'admin';
          id = currentView?.loginID;
          break;
        case ROLE_TYPE.RESELLER:
        case ROLE_TYPE.MULTIOFFICE:
          roleGroup = 'account';
          id = currentView?.loginID;
          break;
        case ROLE_TYPE.EFIN:
          roleGroup = 'office';
          id = currentView?.loginID;
          break;
        case ROLE_TYPE.PREPARER:
          roleGroup = 'preparer';
          id = currentView?.preparerID;
          break;
        case ROLE_TYPE.TECH_SUPPORT:
          roleGroup = 'tech-support';
          id = currentView?.loginID;
          break;
      }

      try {
        const res = await XlinkAPI.search(id, prefix, roleGroup, criteria);
        if (statusOK(res)) {
          setState({ searchResults: res.data });
        }
      } catch (error) {
        ErrorHelpers.handleError('Failed to get search suggestions', error);
        setState({ searchResults: [] });
      }
    } else {
      setState({ searchResults: [] });
    }
  };

  const getResultName = result => {
    return <span className="searchBarResultItem">{`${result.text}`}</span>;
  };

  const getRtnID = result => {
    if (state.searchCriteria !== 'return') {
      return null;
    }
    return <span className="searchBarResultItem">{`ID: ${result.friendlyID}`}</span>;
  };

  const getIsWizardModeReturn = result => {
    if (state.searchCriteria !== 'return' || !result.wizard_mode) {
      return null;
    }
    return (
      <img
        id="wizardModeReturnIcon"
        src={magicWand}
        aria-describedby="This is a Wizard Mode return"
      />
    );
  };

  const renderSearchCriteriaDropdown = () => {
    return (
      <Select
        value={state.searchCriteria}
        onChange={handleFilterChange}
        input={<Input id="DDLSearchDropdown" disableUnderline />}
      >
        {state.dropdownContent.map((entry, i) => {
          return (
            <MenuItem key={entry.name} id={`DDLSeachCriteria${i}`} value={entry.val}>
              {entry.name}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  const updateDropdownDataForRole = () => {
    let dropdownContent = [];
    let searchCriteria = '';

    switch (currentView?.role) {
      case ROLE_TYPE.SUPERUSER:
        dropdownContent = [
          { name: 'Accounts', val: 'account' },
          { name: 'EFIN', val: 'efin' },
          { name: 'Offices', val: 'office' },
          { name: 'Preparers', val: 'preparer' },
          { name: 'Tax Returns', val: 'return' },
          { name: 'Training Returns', val: 'training' },
        ];
        searchCriteria = 'account';
        break;
      case ROLE_TYPE.RESELLER:
        dropdownContent = [
          { name: 'Accounts', val: 'account' },
          { name: 'EFIN', val: 'efin' },
          { name: 'Offices', val: 'office' },
          { name: 'Preparers', val: 'preparer' },
          { name: 'Tax Returns', val: 'return' },
        ];

        searchCriteria = 'account';
        break;
      case ROLE_TYPE.MULTIOFFICE:
        dropdownContent = [
          { name: 'EFIN', val: 'efin' },
          { name: 'Offices', val: 'office' },
          { name: 'Preparers', val: 'preparer' },
          { name: 'Tax Returns', val: 'return' },
        ];

        searchCriteria = 'office';
        break;
      case ROLE_TYPE.EFIN:
        dropdownContent = [
          { name: 'Preparers', val: 'preparer' },
          { name: 'Tax Returns', val: 'return' },
        ];
        searchCriteria = 'return';
        break;
      case ROLE_TYPE.PREPARER:
        dropdownContent = [{ name: 'Tax Returns', val: 'return' }];
        searchCriteria = 'return';
        break;
    }

    // CORP Returns available to specific users.
    if (canAccessCorp(payload, officeProfile, currentView)) {
      dropdownContent = [...dropdownContent, { name: 'Business Returns', val: 'returncorp' }];
    }

    setState({ dropdownContent, searchCriteria });
  };

  const searchInput = (
    <div className="container">
      <Input
        fullWidth
        placeholder={state.placeholderText}
        disableUnderline
        id="txtSearchValueSearchBar"
        onChange={event => handleSearchValueChange(event)}
        value={state.searchValue}
        autoComplete="off"
        className={classes.searchValue}
        onClick={() => {
          if (!isSearchBarVisible) {
            getInitialPlaceholderText();
            updateDropdownDataForRole();
            dispatch(appActions.toggleSearchBar(true));
          }
        }}
      />
      <Divider style={{ width: '16rem', margin: '-4px' }} />
    </div>
  );

  const searchResults = !state.searchResults?.length ? (
    <></>
  ) : (
    state.searchResults.map((result, i) => {
      return (
        <li
          key={i}
          id={`DDLSeachResult${i}`}
          className="searchBarResult"
          onClick={() => onClickSearchResult(i)}
        >
          {getResultName(result)}
          {getRtnID(result)}
          {getIsWizardModeReturn(result)}
        </li>
      );
    })
  );

  return (
    <div className="searchBarContainer">
      <span>
        <FormControl>{searchInput}</FormControl>
        {isSearchBarVisible && (
          <>
            <FormControl>{renderSearchCriteriaDropdown()}</FormControl>
            <FormControl className={classes.searchFormControlBtn}>
              <Button
                id="btnSearchBarCancel"
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => {
                  setState({ ...initialState });
                  dispatch(appActions.toggleSearchBar(false));
                }}
              >
                Cancel
              </Button>
            </FormControl>
          </>
        )}
      </span>
      {!isSearchBarVisible ||
        (state.searchResults?.length > 0 && (
          <div className="searchBarResultListContainer">
            <ul className="searchBarResultList">{searchResults}</ul>
          </div>
        ))}
    </div>
  );
};

export default withRouter(withStyles(styles)(SearchBar));
