// Internal imports
import XlinkAPI from '~/app/api/xlinkAPI';
import ErrorHelpers from '~/app/errorHelpers.js';
import { HIERARCHY_TYPE } from '~/app/constants.js';
// Redux imports
import { takeLatest, call, put } from 'redux-saga/effects';
import { actions as cobrandActions } from '../cobrand/duck';
import { actions, DRILLDOWN, LOAD_PREV_OVERVIEW } from './duck';
import { actions as loginSetupActions } from '~/app/redux/loginSetup/duck.js';

export function* watchDrilldown() {
  yield takeLatest(DRILLDOWN, doDrilldown);
  yield takeLatest(LOAD_PREV_OVERVIEW, doPrevDrilldown);
}

export function* doPrevDrilldown(payload) {
  try {
    // Navigate to dashboard page on a prev drill up.
    yield put(loginSetupActions.setHasFinalizedLogin(true));
  } catch (error) {
    // drilldown previous denied
    ErrorHelpers.handleError('Prev Drilldown not possible', error);
  }
}

export function* doDrilldown(payload) {
  try {
    if (payload.endpoint === undefined) {
      payload.endpoint = `${BASE_URL}/authz/drilldown`;
    }

    const res = yield call(XlinkAPI.post, payload.endpoint, {
      login_id: payload.loginID,
      preparer_id: payload.preparerID,
    });

    yield put(
      actions.setCurrentView({
        loginID: payload.loginID,
        preparerID: res.data?.prep_ID ? res.data.prep_ID : payload.preparerID,
        role: payload.view, // TODO: naming consistency view vs. role
        name: payload.name,
        isOfficeSetupComplete: payload.setupComplete,
        dd_token: res.data.dd_token,
        setupProgress: payload.setupProgress,
        textlinkActivated: payload.textlinkActivated,
      }),
    );

    if (payload.view !== HIERARCHY_TYPE.PREPARER) {
      yield put(cobrandActions.fetchLogo());
    }
  } catch (error) {
    // drilldown denied
    ErrorHelpers.handleError('Drilldown not possible', error);
    yield put(actions.onDrilldownError());
  }
}
