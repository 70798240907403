// External Imports
import React, { useEffect, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Typography,
  Button,
  Paper,
} from '@material-ui/core';
// Internal imports
import { FILING_STATUS, EIN_ENTITY_TYPES } from '~/app/constants.js';
import { useSetState } from '~/app/Utility/customHooks';
// Styling Imports
import './css/TransferData.css';
import { styles } from './css/TransferData.js';

/**
 * Component that allows a user to transfer prior year tax return data from the previous year to current year.
 *
 * @component
 * @category Returns
 * @subcategory TransferData
 */
const TransferData = props => {
  const [state, setState] = useSetState({
    rowSelected: null,
    returnSelected: 0,
    fixReturnDialog: false,
  });

  useEffect(() => {
    if (props.pyReturns.length > 0) {
      setState({
        returnSelected: props.pyReturns[0].tax_return_id,
        rowSelected: 0,
      });
    } else {
      // Close the modal since we have no prior year returns.
      props.onCancelTransferData();
      // If there are any duplicate returns for the current year, we should prompt the user.
      if (props.cyReturns != null) {
        props.openCurrentYearModal();
      }
    }
  }, []);

  /**
   * Closes the import prior year modal.
   *
   * @function
   * */
  const handleCancelClick = () => {
    props.onCancelTransferData();
  };

  const handleYesClick = () => {
    if (state.returnSelected.filing_status === 0 || state.returnSelected.filing_status === null) {
      // prompt to fix filing status
    }

    props.onYesTransfer(state.returnSelected);
  };

  const handleYesOptionClick = option => {
    props.onYesTransferOption(state.returnSelected, option);
  };

  const handleRowClick = (rtn, index) => {
    setState({
      returnSelected: rtn.tax_return_id,
      filingStatus: rtn.filing_status,
      rowSelected: index,
    });
  };

  const mapFlstStatus = flst => {
    let typeTable = FILING_STATUS;

    if (props.isBusiness) {
      typeTable = EIN_ENTITY_TYPES;
    }
    return typeTable[flst] || 'No filing status';
  };

  const { classes } = props;

  const messageForUser = () => {
    if (props.toggleYearToYearOptions) {
      return (
        <Fragment>
          Do you want to replace the existing return?
          <br />
          You can: <br />
          -Clear the current return and re-transfer,
          <br />
          -Keep the current return and re-load ONLY the state data
          <br />
          -Exit without making any changes.
        </Fragment>
      );
    } else {
      const identifier = props.isBusiness ? 'EIN' : 'Social Security Number';
      return (
        <Fragment>
          The {identifier} requested exists in a prior year. Import the prior year return?
        </Fragment>
      );
    }
  };

  return (
    <Paper elevation={5}>
      <Typography variant="body2" id="simple-modal-title" style={{ paddingLeft: '4.5%' }}>
        {props.toggleYearToYearOptions
          ? 'Year-To-Year Data Transfer Options'
          : 'Import the prior year return?'}
      </Typography>
      <Typography style={{ padding: '4.5%' }}>{messageForUser()}</Typography>
      <div className="transferdata-scrollbar ">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{props.isBusiness ? 'Entity Type' : 'Filing Status'}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.pyReturns?.length &&
              props.pyReturns.map((rtn, index) => {
                return (
                  <TableRow
                    classes={{
                      root: classes.tableRowRoot,
                      selected: classes.tableRowSelected,
                    }}
                    key={index}
                    hover={true}
                    selected={state.rowSelected === index}
                    onClick={() => handleRowClick(rtn, index)}
                  >
                    <TableCell>{mapFlstStatus(rtn.filing_status)}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>

      {(() => {
        if (props.toggleYearToYearOptions) {
          return (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                id="btnOKImportReload"
                classes={{ root: classes.btnOptionsRoot }}
                variant="contained"
                color="primary"
                onClick={() => handleYesOptionClick('Reload')}
              >
                Reload
              </Button>
              <Button
                id="btnOKImportStateOnly"
                classes={{ root: classes.btnOptionsRoot }}
                variant="contained"
                color="primary"
                onClick={() => handleYesOptionClick('StateOnly')}
              >
                State Only
              </Button>
              <Button
                id="btnCancelImport"
                classes={{ root: classes.btnOptionsNoBorder }}
                onClick={handleCancelClick}
              >
                Cancel
              </Button>
            </div>
          );
        } else {
          return (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                id="btnCancelImport"
                classes={{ root: classes.btnOptionsNoBorder }}
                onClick={handleCancelClick}
              >
                Cancel
              </Button>
              <Button
                id="btnOKImport"
                classes={{ root: classes.btnOptionsRoot }}
                variant="contained"
                color="primary"
                onClick={() => handleYesClick()}
              >
                OK
              </Button>
            </div>
          );
        }
      })()}
    </Paper>
  );
};

export default withStyles(styles)(TransferData);
