// External imports
import React, { Fragment, useEffect } from 'react';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import {
  AppBar,
  DialogContent,
  Modal,
  Paper,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core';
import get from 'lodash-es/get';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
import WebHelpers, { statusOK } from '~/app/webHelpers.js';
import helpIcon from '~/images/icons/icons8-ask_question.png';
import defaultAvatar from '~/images/managerDash.png';
import Breadcrumbs from '#/Common/Breadcrumbs.jsx';
import {
  LOGIN_SETTINGS,
  DASHBOARD_TITLE_TABS,
  HIERARCHY_TYPE,
  SIDE_TABS,
  SIGNATURE_PAD_TYPES,
  SIGNEE_TYPE,
  RAC_BANK,
} from '~/app/constants.js';
import ErrorHelpers from '~/app/errorHelpers.js';
import { isHierarchySuperOrTech } from '~/app/Utility/general.js';
import AuthAPI from '~/app/api/authAPI.js';
import UtilityAPI from '~/app/api/utilityAPI';
import XlinkAPI from '~/app/api/xlinkAPI';
import SearchBar from '~/app/Components/HeaderBar/components/SearchBar/SearchBar.jsx';
import HelpMenu from '~/app/Components/HeaderBar/components/HelpMenu/HelpMenu.jsx';
import Account from '~/app/Components/HeaderBar/components/Account/Account.jsx';
import AddAvatar from '~/app/Components/HeaderBar/components/AddAvatar/AddAvatar.jsx';
import SwitchOfficeYear from '#/Auth/SwitchOfficeYear.jsx';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import ManageAuth from '#/Auth/ManageAuth.jsx';
import LoginSettings from '#/Settings/LoginSettings/LoginSettings.jsx';
import VersionsModal from '#/Auth/VersionsModal.jsx';
import SignatureModal from '~/app/Components/NewAccount/SingleOffice/SignatureModal.jsx';
import SignaturePreview from '~/app/Components/NewAccount/SingleOffice/SignaturePreviewModal.jsx';
import SignatureCapture from '~/app/Pages/Returns/components/SignatureCapture.jsx';
import TopazSignatureCapture from '~/app/Pages/Returns/components/TopazSignatureCapture.jsx';
import ScriptelSignatureCapture from '~/app/Pages/Returns/components/ScriptelSignatureCapture.jsx';
import PreviewSignature from '#/Common/PreviewSignature.jsx';
// Redux imports
import { useDispatch, useSelector } from 'react-redux';
import { actions as appActions } from '~/app/redux/modules/app';
import { actions as cobrandActions } from '~/app/redux/cobrand/duck';
import { actions as drilldownActions } from '~/app/redux/drilldown/duck';
import { actions as loginSetupActions } from '~/app/redux/loginSetup/duck';
import { actions as returnProfileActions } from '~/app/redux/returnProfile/duck';
// Styling imports
import '~/app/Components/HeaderBar/css/headerBar.css';
import { styles } from '~/app/Components/HeaderBar/css/headerBar.js';

const excludedComponents = [
  '/account-settings',
  '/create-new-account',
  '/database',
  '/refund-calculator',
  '/wizard-mode',
];

/**
 * Header Bar component at the top of the page to house the search, help, and
 * profile menu.
 *
 * @component
 * @category Components
 * @subcategory HeaderBar
 */
const HeaderBar = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const payload = WebHelpers.getJWTPayload();
  const { classes } = props;

  const {
    drilldownHistory,
    isTrainingMode,
    logo,
    avatar,
    isAuthenticated,
    isAccountVisible,
    isHelpMenuVisible,
    impersonation,
    loggedInUser,
    isAddAvatarVisible,
    isTaxyearOfficeSwitchVisible,
    isOfficeSwitchVisible,
    isTaxyearSwitchVisible,
    loginSettings,
    isVersions,
    showSignaturePad,
    showOnScreenPad,
    showScriptelPad,
    showSignaturePreview,
    showTopazPad,
    showNoCaptureSignMessage,
    // activeAccessLevels,
    hasFinalizedLogin,
    licenseInfo,
    efinID,
    profileOpen,
  } = useSelector(state => ({
    drilldownHistory: state.drilldown.drilldownHistory,
    isTrainingMode: state.loginSetup.isTrainingMode,
    logo: state.cobrand.logo,
    avatar: state.app.avatarBase64,
    isAuthenticated: state.app.isAuthenticated,
    isAccountVisible: state.app.isAccountVisible,
    isHelpMenuVisible: state.app.isHelpMenuVisible,
    impersonation: state.app.impersonation,
    loggedInUser: state.app.loggedInUser,
    isAddAvatarVisible: state.app.isAddAvatarVisible,
    isTaxyearOfficeSwitchVisible: state.app.isTaxyearOfficeSwitchVisible,
    isOfficeSwitchVisible: state.app.isOfficeSwitchVisible,
    isTaxyearSwitchVisible: state.app.isTaxyearSwitchVisible,
    loginSettings: state.loginSetup.loginSettings,
    isVersions: state.app.isVersions,
    showSignaturePad: state.app.showSignaturePad,
    showOnScreenPad: state.app.showOnScreenPad,
    showScriptelPad: state.app.showScriptelPad,
    showSignaturePreview: state.app.showSignaturePreview,
    showTopazPad: state.app.showTopazPad,
    showNoCaptureSignMessage: state.app.showNoCaptureSignMessage,
    // activeAccessLevels: loginSelector.getActiveAccessLevels(state),
    hasFinalizedLogin: state.loginSetup.hasFinalizedLogin,
    licenseInfo: state.officeProfile.licenseInfo,
    efinID: state.officeProfile?.efin_id,
    profileOpen: state.returnProfile.profileOpen,
  }));

  const [state, setState] = useSetState({
    showUploadAvatarSuccessDialog: false,
    showResetAvatarDialog: false,
    authManagementOpen: false,
    authManagementMode: 0,
    showLoginSettingsDialog: false,
    eroUploadedSignature: false,
    successfulSignatureUpdate: false,
    preparerUploadedSignature: undefined,
    preparerSignatureData: false,
    eroSignatureData: undefined,
    openSignatureExists: false,
    signatureRecapture: false,
    signeeType: 0,
    hasRepublicBankApproval: false,
  });

  useEffect(() => {
    if (hasFinalizedLogin) {
      dispatch(loginSetupActions.setHasFinalizedLogin(false));
      history.push({ pathname: '/dashboard' });
    }
  }, [hasFinalizedLogin]);

  useEffect(() => {
    if (efinID) {
      getBankList();
    }
  }, [efinID]);

  const getBankList = async () => {
    try {
      const response = await XlinkAPI.getBankList(efinID, payload.season);
      if (response) {
        if (response.data?.includes(RAC_BANK.REPUBLIC_BANK)) {
          setState({ hasRepublicBankApproval: true });
        }
      }
    } catch (err) {
      ErrorHelpers.handleError(
        'Unable to fetch bank list',
        ErrorHelpers.createSimpleError(
          'If this problem persists, please contact technical support',
        ),
      );
    }
  };

  const isDrilldown =
    drilldownHistory !== undefined &&
    drilldownHistory.length > 1 &&
    location.pathname !== '/refund-calculator';

  /** Handles opening the help modal. */
  const onClickHelp = () => {
    if (payload.hierarchy_type_id === HIERARCHY_TYPE.TECH_SUPPORT_SBA) {
      return; // do nothing in the case Service Bureau tech support is logged in
    }

    if (isAccountVisible) {
      dispatch(appActions.toggleAccountModal());
    }
    dispatch(appActions.toggleHelpModal());
  };

  /** Handles opeing the profile modal.  */
  const onClickProfile = () => {
    if (isHelpMenuVisible) {
      dispatch(appActions.toggleHelpModal());
    }
    dispatch(appActions.toggleAccountModal());
  };

  /** Determines when to show the header bar. */
  const handleDisplayingHeaderBar = () => {
    if (
      isAuthenticated &&
      location.pathname !== '/tax-return' &&
      !excludedComponents.some(v => location.pathname.includes(v))
    )
      return true;
  };
  /** Determines if search bar should be render */
  const canShowSearchBar =
    !isHierarchySuperOrTech(payload?.hierarchy_type_id) || drilldownHistory.length > 1;

  /**
   * Gets the generic overviw for SB.
   *
   * @param {number} loginID - The login ID of the user.
   */
  /* const getPreviewTable = async loginID => {
    // Get Widget Config
    if (activeAccessLevels?.view_widgets) {
      dispatch(appActions.loadWidgetConfiguration());
    }

    try {
      const res = await XlinkAPI.getGenericOverview(loginID, props.count);
      if (statusOK(res)) {
        if (res?.data?.data) {
          this.props.updateSBTable(res.data.data);
        }

        // Set agreements status in redux here
        if (res?.data?.agreementsStatus) {
          this.props.setAgreementsStatus(res.data.agreementsStatus);
        }
      }
    } catch (err) {
      ErrorHelpers.handleError('Fetch Error', err);
    }
  }; */

  /**
   * Handles when the breadbrumb links are clicked.
   *
   * @param {number} idx - The index of the breadcrumb.
   */
  const handleBreadcrumbClick = idx => {
    if (drilldownHistory.length > 1 && idx !== drilldownHistory.length - 1) {
      dispatch(appActions.onSelectSidebarTab(SIDE_TABS.DASHBOARD_MAIN));
      dispatch(appActions.onSelectTitleTab(DASHBOARD_TITLE_TABS.OVERVIEW));
      dispatch(drilldownActions.loadPrevOverview(idx));
      if (drilldownHistory[idx].role !== HIERARCHY_TYPE.PREPARER) {
        dispatch(cobrandActions.fetchLogo(false));
      }
    } else if (impersonation && drilldownHistory.length === 1) {
      dispatch(loginSetupActions.impersonateLogin('')); // Blank identifier signifies reverting to regular jwt payload
    }
  };

  const onClickContinueFromPickOffice = async (efin_id = 0, season) => {
    try {
      await AuthAPI.selectAnOfficeYear(efin_id, season);
      const basicInfo = await UtilityAPI.getBasicOfficeInfo(drilldownHistory);
      if (basicInfo !== undefined) {
        if (basicInfo?.result) {
          dispatch(appActions.setLoggedInBasicInfo(basicInfo.result));
        } else {
          // basic info not wrapped in result object but exists, place in store directly
          dispatch(appActions.setLoggedInBasicInfo(basicInfo));
        }

        // Set agreements status in redux here
        if (basicInfo?.agreementsStatus !== undefined) {
          this.props.setAgreementsStatus(basicInfo.agreementsStatus);
        }
      }

      dispatch(drilldownActions.resetDrilldown());
      // all good, try onFinalizeLogin again
      dispatch(loginSetupActions.onFinalizeLogin());

      // If users are on the return profile, we do not want them to remain on a TY22 returnprofile if on TY23
      if (profileOpen) {
        history.push({ pathname: '/tax-returns' });
        dispatch(returnProfileActions.closeReturnProfile());
      }
    } catch (error) {
      ErrorHelpers.handleError('Error selecting office', error);
    }
  };

  const onClickUploadAvatar = base64Image => {
    dispatch(appActions.toggleAddAvatar());

    XlinkAPI.post(`${XLINK_API_URL}/user/avatar`, { avatar: base64Image })
      .then(() => {
        setState({ showUploadAvatarSuccessDialog: true });
        dispatch(appActions.setAvatar(base64Image));
      })
      .catch(error => {
        ErrorHelpers.handleError('Error updating avatar', error);
      });
  };

  const onClickResetAvatarToDefault = () => {
    setState({ showResetAvatarDialog: true });
  };

  const hideUploadedAvatarSuccessDialog = () => {
    setState({ showUploadAvatarSuccessDialog: false });
  };

  const closeManageAuth = () => {
    setState({
      authManagementOpen: false,
    });
  };

  const hideResetAvatarDialog = () => {
    dispatch(appActions.toggleAddAvatar());
    setState({ showResetAvatarDialog: false });
  };

  const deleteAvatar = () => {
    XlinkAPI.post(`${XLINK_API_URL}/user/avatar/delete`)
      .then(() => {
        dispatch(appActions.setAvatar(''));
      })
      .catch(error => {
        ErrorHelpers.handleError('Error deleting avatar', error);
      });
  };

  const confirmResetAvatarDialog = () => {
    dispatch(appActions.toggleAddAvatar());
    setState({ showResetAvatarDialog: false });
    deleteAvatar();
  };

  /** Handles request that checks if we are set in Training Mode Only by a supervisor account */
  const handleClickingTrainingMode = () => {
    dispatch(loginSetupActions.toggleTrainingMode());
    dispatch(appActions.toggleSearchBar());
  };

  const saveLoginSettings = () => {
    dispatch(loginSetupActions.updateLoginSettings(loginSettings));
    toggleLoginSettingsDialog(false, false);
  };

  const toggleLoginSettingsDialog = (toggle, fetchSettings) => {
    if (fetchSettings) {
      const showIRSFlag = sessionStorage.getItem(LOGIN_SETTINGS.SHOW_IRS_MESSAGE);
      dispatch(loginSetupActions.fetchLoginSettings(showIRSFlag));
    }
    setState({ showLoginSettingsDialog: toggle });
  };

  // Close signature exists modal
  const closeSignatureExistsModal = () => {
    setState({ openSignatureExists: false });
  };

  // setSignaturePadType sets the signature pad type based on login setting configurations
  const setSignaturePadType = () => {
    if (loginSettings) {
      if (get(loginSettings, 'onScreen.value', false)) {
        dispatch(appActions.setOnscreenSignatureState(true));
      }
      if (get(loginSettings, 'scriptel.value', false)) {
        dispatch(appActions.setScriptelSignatureState(true));
      }
      if (get(loginSettings, 'topaz.value', false)) {
        dispatch(appActions.setTopazSignatureState(true));
      }
      if (
        loginSettings.onScreen.value !== true &&
        loginSettings.scriptel.value !== true &&
        loginSettings.topaz.value !== true
      ) {
        // Default signature pad to on-screen, when no signature pad selection has been made
        dispatch(appActions.setOnscreenSignatureState(true));
      }
    } else {
      // Default signature pad to on-screen, when no signature pad selection has been made
      dispatch(appActions.setOnscreenSignatureState(true));
    }
  };

  // Set signature recapture to true
  const allowSignatureRecapture = () => {
    setSignaturePadType();
    setState({ openSignatureExists: false });
    setState({ signatureRecapture: true });
  };

  // Close on-screen signature pad
  const closeNewCaptureSignatureModal = () => {
    setState({ signatureRecapture: false });
    dispatch(appActions.setOnscreenSignatureState(false));
  };

  // Close Topaz signature pad
  const closeNewCaptureSignatureModalTopaz = () => {
    setState({ signatureRecapture: false });
    dispatch(appActions.setTopazSignatureState(false));
  };

  // Close Scriptel signature pad
  const closeNewCaptureSignatureModalScriptel = () => {
    setState({ signatureRecapture: false });
    dispatch(appActions.setScriptelSignatureState(false));
  };

  // close Successful Signature Update Modal
  const closeSignatureUpdateModal = () => {
    setState({ successfulSignatureUpdate: false });
  };

  const handleNewSignatureCapture = (sigType, sigValue, padType) => {
    // Post signature to database
    if (padType === SIGNATURE_PAD_TYPES.ON_SCREEN) {
      closeNewCaptureSignatureModal();
    }
    if (padType === SIGNATURE_PAD_TYPES.TOPAZ) {
      closeNewCaptureSignatureModalTopaz();
    }
    if (padType === SIGNATURE_PAD_TYPES.SCRIPTEL) {
      closeNewCaptureSignatureModalScriptel();
    }

    // Call XlinkAPI endpoint to post signature to db
    XlinkAPI.postSignature(sigValue, padType, state.signeeType)
      .then(() => {
        setState({ successfulSignatureUpdate: true });
      })
      .catch(error => {
        ErrorHelpers.handleError('Error Updating Signature', error);
      });
  };

  const checkForPreparerSignature = async () => {
    try {
      const fetchPrepSignature = await XlinkAPI.getSignatureForPreparer();
      let hasPrepSignature = false;

      if (statusOK(fetchPrepSignature) && get(fetchPrepSignature, 'data', false)) {
        hasPrepSignature = fetchPrepSignature.data.length > 0;

        setState({
          preparerSignatureData: fetchPrepSignature.data,
          preparerUploadedSignature: hasPrepSignature,
          openSignatureExists: true,
          signeeType: SIGNEE_TYPE.PREPARER,
        });
      }

      return hasPrepSignature;
    } catch (err) {
      ErrorHelpers.handleError('Capture Preparer Signature Error', err);
    }
  };

  const fetchEROSignatureData = async () => {
    try {
      const fetchEROSignature = await XlinkAPI.getSignatureForERO();
      let hasEROSignature = false;

      if (statusOK(fetchEROSignature) && get(fetchEROSignature, 'data', false)) {
        hasEROSignature = fetchEROSignature.data.length > 0;

        setState({
          eroSignatureData: fetchEROSignature.data,
          eroUploadedSignature: hasEROSignature,
          openSignatureExists: true,
          signeeType: SIGNEE_TYPE.ERO,
        });
      }
      return hasEROSignature;
    } catch (err) {
      ErrorHelpers.handleError('Capture ERO Signature Error', err);
    }
  };

  // onClickSignature opens the ERO/preparer signature form db
  const onClickSignature = async signeeType => {
    // Call endpoint to get the signature data, which will include the signature, and the device that was used to sign
    let hasEROSignature = false;
    let hasPrepSignature = false;

    // Check signee type to decide which endpoint to call
    if (signeeType === SIGNEE_TYPE.PREPARER) {
      // Check to see if we have a preparer signature on db
      hasPrepSignature = await checkForPreparerSignature();
    }
    if (signeeType === SIGNEE_TYPE.ERO) {
      // Check to see if we have an ERO signature on db
      hasEROSignature = await fetchEROSignatureData();
    }

    setState({ signeeType });
    // Close the account modal (login menu)
    dispatch(appActions.toggleAccountModal());

    !hasEROSignature && !hasPrepSignature && setSignaturePadType();
  };

  if (handleDisplayingHeaderBar()) {
    const Imagesrc = avatar === '' ? defaultAvatar : avatar;
    const demoImpTrain = isTrainingMode || isDrilldown || false;

    return (
      <div className="header-bar-block">
        {location.pathname !== '/refund-calculator' && (
          <Breadcrumbs
            handleClick={idx => handleBreadcrumbClick(idx)}
            showFieldAsLinks={true}
            fields={location.pathname !== '/refund-calculator' && drilldownHistory}
            passClassName={`${!isTrainingMode ? 'breadcrumb-list' : 'training-breadcrumb-list'}`}
            onClickTrainingMode={handleClickingTrainingMode}
            isInTrainingMode={isTrainingMode}
            demoAccountFlag={payload?.account_code === 'CLODMO' || false}
            impersonation={impersonation}
            displayName={loggedInUser.displayName}
          />
        )}
        <AppBar position="fixed">
          <Toolbar className="toolbar-color">
            <div className="header-bar-logo-block">
              <img
                src={logo}
                className={`${!demoImpTrain ? 'logoImgAppBarNoMargin' : 'logoImgAppBar'}`}
                onClick={() => history.push({ pathname: '/dashboard' })}
              />
            </div>
            {canShowSearchBar && (
              <div
                className={`${
                  !demoImpTrain
                    ? 'header-bar-search-bar-block'
                    : 'header-bar-search-bar-block-training'
                }`}
              >
                <SearchBar />
              </div>
            )}
            <div className={`${!demoImpTrain ? 'userActionsAvatar' : 'userActionsTraining'}`}>
              <div className="header-bar-help-block">
                <img
                  id="imgHelpHeaderBar"
                  src={helpIcon}
                  className="noselect helpIcon"
                  onClick={onClickHelp}
                />
                <Typography
                  htmlFor="imgHelpHeaderBar"
                  className={classes.helpTypography}
                  onClick={onClickHelp}
                >
                  Help
                </Typography>
              </div>
              <Modal
                className={classes.helpMenu}
                open={isHelpMenuVisible}
                onClose={() => dispatch(appActions.toggleHelpModal())}
                BackdropProps={{
                  classes: {
                    root: classes.backDrop,
                  },
                }}
              >
                <div>
                  <HelpMenu
                    account_code={payload?.account_code}
                    hasRepublicBankApproval={state.hasRepublicBankApproval}
                  />
                </div>
              </Modal>
              <div className="loggedin-user">
                <span
                  className="acct-profile-title-bar-container"
                  id="spnProfileHeaderBar"
                  onClick={onClickProfile}
                >
                  <div className="initial-block-title-bar">
                    <img src={Imagesrc} className="avatarTitleBar" />
                  </div>
                  <div className="user-info-block-title-bar">
                    <div className="large-text-title-bar">{payload ? payload.full_name : ''}</div>
                    <div className="small-text-title-bar">
                      {`Tax Year ${payload ? payload.season - 1 : ''}`}
                    </div>
                  </div>
                </span>
                <label
                  htmlFor="spnProfileHeaderBar"
                  className="arrow-down account-title-bar"
                  onClick={onClickProfile}
                />
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <Modal
          className={classes.account}
          open={isAccountVisible}
          onClose={() => dispatch(appActions.toggleAccountModal())}
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
          }}
        >
          <DialogContent className={classes.dialogContent}>
            <Account
              setAuthManagementOpen={flag => setState({ authManagementOpen: flag })}
              setAuthManagementMode={val => setState({ authManagementMode: val })}
              toggleLoginSettingsDialog={(toggle, fetchSettings) =>
                toggleLoginSettingsDialog(toggle, fetchSettings)
              }
              onClickSignature={signeeType => onClickSignature(signeeType)}
            />
          </DialogContent>
        </Modal>
        <Modal className={classes.avatarPicker} open={isAddAvatarVisible}>
          <DialogContent className={classes.dialogContent}>
            <AddAvatar
              onClickUploadImage={onClickUploadAvatar}
              onResetToDefault={onClickResetAvatarToDefault}
            />
          </DialogContent>
        </Modal>
        <SimpleDialog
          open={state.showUploadAvatarSuccessDialog}
          onConfirm={hideUploadedAvatarSuccessDialog}
          dialogTitle="IMAGE UPLOADED"
          contentText="The image was uploaded successfully."
          confirmText="OK"
          styled
        />
        <SimpleDialog
          open={state.showResetAvatarDialog}
          onClose={hideResetAvatarDialog}
          onConfirm={confirmResetAvatarDialog}
          dialogTitle="RESET TO DEFAULT"
          contentText="Are you sure you want to reset you avatar to the default?"
          confirmText="Yes"
          cancelText="No"
          styled
        />
        <Modal
          className={classes.switch}
          open={isTaxyearOfficeSwitchVisible}
          onClose={() => dispatch(appActions.toggleSwitchOfficeYear())}
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
          }}
        >
          <DialogContent className={classes.dialogContent}>
            <SwitchOfficeYear
              onClose={() => dispatch(appActions.toggleSwitchOfficeYear())}
              onClickContinue={(efinID, season) => {
                dispatch(appActions.toggleSwitchOfficeYear());
                onClickContinueFromPickOffice(efinID, season);
              }}
              taxyearSwitchModalActive={isTaxyearSwitchVisible}
              officeSwitchModalActive={isOfficeSwitchVisible}
              payload={payload}
              drilldownHistory={drilldownHistory}
              licenseInfo={licenseInfo}
            />
          </DialogContent>
        </Modal>
        <ManageAuth
          closeAuthModal={closeManageAuth}
          openAuthModal={state.authManagementOpen}
          isResetPassMode={state.authManagementMode !== 1}
          onLogout={() => dispatch(appActions.onLogout())}
        />
        <Modal
          id="mdlLoginSettings"
          disableBackdropClick
          open={state.showLoginSettingsDialog}
          onClose={() => toggleLoginSettingsDialog(false, true)}
        >
          <Paper elevation={5} className={classes.settingsModals}>
            <LoginSettings
              loginSettings={loginSettings}
              setLoginSettings={settings => dispatch(loginSetupActions.setLoginSettings(settings))}
              handleClose={(toggle, fetch) => toggleLoginSettingsDialog(toggle, fetch)}
              handleSave={() => saveLoginSettings()}
            />
          </Paper>
        </Modal>
        <Modal
          id="mdlLoginSettings"
          disableBackdropClick
          open={isVersions}
          onClose={() => dispatch(appActions.toggleVersions())}
        >
          <Paper elevation={5} className={classes.versionsModal}>
            <VersionsModal
              isOpen={isVersions}
              onClose={() => dispatch(appActions.toggleVersions())}
              shouldCloseOnOverlayClick
            />
          </Paper>
        </Modal>
        <SimpleDialog
          open={showNoCaptureSignMessage}
          onConfirm={() => dispatch(appActions.toggleNoCaptureSignature())}
          dialogTitle="Login type is of type Preparer"
          contentText="To Capture an ERO Signature you must have a login type of Manager. Logins can be updated in Settings although changes may take a few minutes to reflect on account"
          confirmText="OK"
          styled
        />
        <Modal open={showSignaturePad} onClose={() => dispatch(appActions.toggleSignature())}>
          <Paper
            elevation={5}
            style={{
              width: '520px',
              padding: '0',
              margin: 'auto',
            }}
          >
            <SignatureModal
              onClose={() => dispatch(appActions.toggleSignature())}
              onPreview={() => dispatch(appActions.toggleSignaturePreview())}
              modalOptions={{ dismissible: false }}
              eroSignatureData={state.eroSignatureData}
              eroUploadedSignature={state.eroUploadedSignature}
            />
          </Paper>
        </Modal>
        <Modal
          id="mdlAddSignatureMenuOnScreen"
          aria-labelledby="simple-modal-title-signature-menu-onscreen"
          aria-describedby="simple-modal-description-signature-menu-onscreen"
          open={
            (state.preparerUploadedSignature || state.eroUploadedSignature) &&
            state.openSignatureExists
          }
          onClose={closeSignatureExistsModal}
          disableAutoFocus
          disableBackdropClick
        >
          <PreviewSignature
            prepSignature={state.preparerSignatureData}
            eroSignature={state.eroSignatureData}
            handleNewClose={closeSignatureExistsModal}
            signatureRecapture={allowSignatureRecapture}
            signeeType={state.signeeType}
          />
        </Modal>
        <Modal
          id="mdlAddSignatureMenuOnScreen"
          aria-labelledby="simple-modal-title-signature-menu-onscreen"
          aria-describedby="simple-modal-description-signature-menu-onscreen"
          open={
            showOnScreenPad &&
            (state.signatureRecapture ||
              !state.preparerUploadedSignature ||
              !state.eroUploadedSignature)
          }
          onClose={() => appActions(appActions.setOnscreenSignatureState(false))}
          disableAutoFocus
          disableBackdropClick
        >
          <SignatureCapture
            currentSig={state.signeeType}
            handleSignatureCapture={(sigType, sigValue, padType) =>
              handleNewSignatureCapture(sigType, sigValue, padType)
            }
            handleClose={closeNewCaptureSignatureModal}
            convertString={false}
          />
        </Modal>
        <Modal
          id="mdlAddSignatureMenuTopaz"
          aria-labelledby="simple-modal-title-signature-menu-topaz"
          aria-describedby="simple-modal-description-signature-menu-topaz"
          open={
            showTopazPad &&
            (state.signatureRecapture ||
              !state.preparerUploadedSignature ||
              !state.eroUploadedSignature)
          }
          onRequestClose={() => dispatch(appActions.setTopazSignatureState(false))}
          style={{ top: '20%' }}
          disableAutoFocus
          disableBackdropClick
        >
          <TopazSignatureCapture
            currentSig={1}
            handleSignatureCapture={(sigType, sigValue, padType) =>
              handleNewSignatureCapture(sigType, sigValue, padType)
            }
            handleClose={closeNewCaptureSignatureModalTopaz}
            convertString={false}
          />
        </Modal>
        <Modal
          id="mdlAddSignatureMenuScriptel"
          aria-labelledby="simple-modal-title-signature-menu-scriptel"
          aria-describedby="simple-modal-description-signature-menu-scriptel"
          open={
            showScriptelPad &&
            (state.signatureRecapture ||
              !state.preparerUploadedSignature ||
              !state.eroUploadedSignature)
          }
          onRequestClose={() => dispatch(appActions.setScriptelSignatureState(false))}
          style={{ top: '20%' }}
          disableAutoFocus
          disableBackdropClick
        >
          <ScriptelSignatureCapture
            currentSig={1}
            handleSignatureCapture={(sigType, sigValue, padType) =>
              handleNewSignatureCapture(sigType, sigValue, padType)
            }
            handleClose={closeNewCaptureSignatureModalScriptel}
            convertString={false}
          />
        </Modal>
        <SimpleDialog
          open={state.successfulSignatureUpdate}
          onConfirm={closeSignatureUpdateModal}
          dialogTitle="Signature Updated"
          contentText="Signature Has Been Updated Successfully"
          confirmText="OK"
          styled={true}
        />
        <Modal
          open={showSignaturePreview}
          onRequestClose={() => dispatch(appActions.toggleSignaturePreview())}
        >
          <Paper
            elevation={5}
            style={{
              height: '350px',
              width: '520px',
              padding: '0',
              margin: 'auto',
            }}
          >
            <SignaturePreview
              modalOptions={{ dismissible: false }}
              onClose={() => dispatch(appActions.toggleSignaturePreview())}
              onRecapture={() => dispatch(appActions.toggleSignature())}
            />
          </Paper>
        </Modal>
      </div>
    );
  }
  return <Fragment />;
};

export default withRouter(withStyles(styles)(HeaderBar));
